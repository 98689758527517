import * as d3 from 'd3';
import * as _ from 'lodash';
import * as moment from 'moment';

import { SAIEvent } from './sai-event-core';
import { SAIEventManager } from './sai-event-core';


enum SAIEventInterfaceDisplayMode {
    YEARS, MONTHES, WEEKS, MONDAYS, DAYS, DAYS_EXTENDED, THREE_HOURS, HOURS
}

class SAIEventInterfaceConfig{
    
    public title: string;
    public locale: string;

    public colorset: any;
    public colorCriteria: string;
    public timeStartCriteria: string;
    public timeEndCriteria: string;
    public dataGrouping: Array<string>;
    public dataHeaders: Array<any>;
    public eventValueCriteria: Array<string>;
    public initialDisplayMode;
    public legendDialog;
    public instanceId: string;
    public availableModes: Array<SAIEventInterfaceDisplayMode>;

    constructor(options: any){
        // d3 color function
        this.colorset = options.colorset || d3.schemeCategory10;
        // color column that will holds the value
        this.colorCriteria = options.colorCriteria || null;
        // interface title
        this.title = options.title || null;
        // interface language
        this.locale = options.locale || 'fr';
        // column that represents event start date
        this.timeStartCriteria = options.timeStartCriteria || '';
        // column that represents event end date
        this.timeEndCriteria = options.timeEndCriteria || '';
        //List of data column ids that will allow to group data
        this.dataGrouping = options.dataGrouping || [];
        //This should hold objects representing headers of the data columns
        this.dataHeaders = options.dataHeaders || [];
        // List of data column that define the displayed content of events
        this.eventValueCriteria = options.eventValueCriteria || [];
        //Default display mode
        this.initialDisplayMode = options.displayMode || undefined;

        this.legendDialog = options.legendDialog || undefined;
        this.instanceId = options.instanceId || Math.random().toString(36).replace(/[^a-z]+/g, '');
        this.availableModes = options.availableModes || [options.displayMode];
    }
}

class SAIEventInterface {
    protected config: SAIEventInterfaceConfig;
    protected rootElement: any;
    protected jqueryRootElement: JQuery<HTMLElement>;
    protected width: number;
    protected height: number;
    protected eventsMap: { [key: string]: SAIEvent};
    protected debug: boolean;
    protected manager: SAIEventManager;
    protected currentVisualMode: SAIEventInterfaceDisplayMode;
    protected eventsListeners : {
        [eventName: string] : Array<any>
    }

    protected start: moment.Moment;
    protected end: moment.Moment;
    protected availableModes: Array<SAIEventInterfaceDisplayMode>;

    constructor(context: any){
        let me = this;
        this.config = this.buildConfig(context);

        this.rootElement = context.rootElement? d3.select(context.rootElement[0]) : undefined;
        this.jqueryRootElement = context.rootElement;
        this.width = context.width;
        this.height = context.height;

        this.eventsMap = {};

        this.debug = context.debug === true;

        let availableModes = this.getAllowedModes();

        this.setManager(context.manager);

        if(this.config.initialDisplayMode !== undefined && _.findIndex(availableModes, function(item) { return item == me.config.initialDisplayMode}) > -1) {
            this.setMode(this.config.initialDisplayMode);
        } else if(availableModes.length > 0) {
            this.setMode(availableModes[0]);
        }

        if(context.listeners) {
            for(let key in context.listeners) {
                this.addListener(key, context.listeners[key]);
            }
        }
    }

    public setMode(mode :SAIEventInterfaceDisplayMode, date?: moment.Moment) : void{
        let allowedModes = this.getAllowedModes();
        for(let key in allowedModes) {
            if(allowedModes[key] === mode) {
                this.currentVisualMode = mode;
                this.resetInterface(date);
                return;
            }
        }
        console.error('Given mode ' + mode + ' is not allowed in the current interface');
    }

    buildConfig(context: any): any {
        return new SAIEventInterfaceConfig(context);
    }

    getStart(): moment.Moment {
        return this.start;
    }

    getEnd(): moment.Moment {
        return this.end;
    }

    getManager() : SAIEventManager {
        return this.manager;
    }

    getRootElement(): any {
        return this.rootElement;
    }

    setManager(manager: SAIEventManager) : void{
        if(!manager || ! (manager instanceof SAIEventManager) ){
            console.error('Invalid configuration of interface. The given manager is not a valid SAIEventManager object');
            if(this.debug){
                let debugColor = 'color: #0F52BA;';   
                console.debug('%cThe given invalid object is %O', debugColor, manager); 
            }
            return;    
        }

        if(this.debug){
            let debugColor = 'color: #0F52BA;';
            if(!this.manager){
                console.debug('%cThere was no previous manager', debugColor);
            }else{
                console.debug('%cThe following manager has been discarded : %O', debugColor, this.manager);
            }
            console.debug('%cSetting up the new manager : %O', debugColor, manager);
        }

        this.manager = manager;
        manager.setRenderer(this);
    }

    getJQueryElement(): JQuery<HTMLElement> {
        return this.jqueryRootElement;
    }

    getWidth(): number {
        return this.width;
    }

    getHeight(): number {
        return this.height;
    }

    getConfig() : SAIEventInterfaceConfig {
        return this.config !== undefined? this.config : null;
    }

    setConfig(newConfig: SAIEventInterfaceConfig) : void{
        this.config = newConfig;
        this.resetInterface();
    }

    resetInterface(date? : moment.Moment) : void{
        if(this.checkValidState()){
            //delete all svg elements
            this.rootElement.html('');

            //redraw everything
            this.drawInterface();
            this.drawCategories();
            this.renderEvents(this.start, this.end);
            this.setupListeners();
        }
    }

    protected setupListeners() : void {

    }

    checkValidState() : boolean{
        if(this.debug){
            let debugColor = 'color: #0F52BA;';
            if(!this.rootElement){
                console.debug('%cNo associated root element to draw on', debugColor);
            }else{
                console.debug('%cThe following element will be used for drawing : %O', debugColor, this.rootElement);
            }
        }

        if(!this.rootElement){
            console.warn('No root element, nothing will be rendered');
            return false;
        }else{
            console.debug('Rendering is allowed');
            return true;
        }
    }

    addEvents(events: Array<SAIEvent>){
        console.warn('default implementation is empty');
    }

    renderEvents(start?: moment.Moment, end?: moment.Moment){
        console.warn('default implementation is empty');
    }

    drawEventsList(events: Array<SAIEvent>) {
        console.warn('default implementation is empty');
    }

    drawCategories(){
        console.warn('default implementation is empty');
    }

    public drawInterface(): void{
        console.warn('default implementation is empty');
    }

    public setStart(start: moment.Moment) : void {
        this.start = start;
    }

    public setEnd(end: moment.Moment) : void {
        this.end = end;
    }

    public getAllowedModes() : Array<SAIEventInterfaceDisplayMode> {
        return this.availableModes;
    }

    public addListener(eventName: string, callback) : void {
        if(!this.eventsListeners) {
            this.eventsListeners = {};
        }

        if(!this.eventsListeners[eventName]) {
            this.eventsListeners[eventName] = [];
        }

        this.eventsListeners[eventName].push(callback);
    }

    public removeListener(eventName: string, callback?): boolean {
        if(!this.eventsListeners) {
            return false;
        }

        if(!this.eventsListeners[eventName]) {
            return false;
        }

        let foundSome = false;
        var i = this.eventsListeners[eventName].length
        while (i--) {
            let curContent = this.eventsListeners[eventName][i];
            if(callback && callback === curContent.callback) {
                this.eventsListeners[eventName].splice(i, 1);
                foundSome = true;
            } else if(!callback) {
                this.eventsListeners[eventName].splice(i, 1);
                foundSome = true;
            }
        }

        return foundSome;
    }

    public triggerEvent(eventName: string, args: Array<any>) {
        if(this.eventsListeners && this.eventsListeners[eventName]) {
            for(let key in this.eventsListeners[eventName]) {
                let handler = this.eventsListeners[eventName][key];
                handler.apply(null, args);
            }
        }
    }
}

export { SAIEventInterface, SAIEventInterfaceConfig, SAIEventInterfaceDisplayMode };