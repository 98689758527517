import * as moment from "moment";
import { SAIEventCalendar, SAICalendarConfig } from './sai-event-calendar';
import { SAICalendarModeRenderer } from './sai-event-calendar-mode';
import { SAIEvent } from './sai-event-core';
import * as d3 from 'd3';

class SAICalendarYearsRenderer extends SAICalendarModeRenderer {
    private daysOfWeek: Array<{ name: string, class: string}>;
    private daysMap: { [key: string] : {data: Array<any>, text?: any}};
    private calendarInterface: SAIEventCalendar;

    private rootElement: any;
    private svgRoot: any;
    private daysGrid: any;
    private newEventMarker: any;
    private dropShadow: any;
    private daysHeaders: any;

    private colSize: number;
    private rowSize: number;

    private __origin__ : any;
    private __zoomValue__ : number;

    constructor(options: { currentDate: moment.Moment, config: SAICalendarConfig, calendarInterface: SAIEventCalendar, rootElement: any}) {
        super(options);
        this.calendarInterface = options.calendarInterface;
        this.rootElement = options.rootElement;
        this.daysOfWeek = [{name: this.currentDate.isoWeekday(1).format(this.config.headerFormat), class: 'working-day'},
            {name: this.currentDate.isoWeekday(2).format(this.config.headerFormat), class: 'working-day'},
            {name: this.currentDate.isoWeekday(3).format(this.config.headerFormat), class: 'working-day'},
            {name: this.currentDate.isoWeekday(4).format(this.config.headerFormat), class: 'working-day'},
            {name: this.currentDate.isoWeekday(5).format(this.config.headerFormat), class: 'working-day'},
            {name: this.currentDate.isoWeekday(6).format(this.config.headerFormat), class: 'non-working-day'},
            {name: this.currentDate.isoWeekday(7).format(this.config.headerFormat), class: 'non-working-day'}
        ];
    }

    public renderInterface() : void {
        var me = this;
        let interfaceWidth = this.calendarInterface.getWidth();
        let interfaceHeight = this.calendarInterface.getHeight();

        this.svgRoot = this.rootElement.append('svg')
                .attr('width', interfaceWidth)
                .attr('height', interfaceHeight)
                .attr('class', 'calendar calendar-year')
                .append('g');

        var nbMonthRow;
        if (interfaceWidth <= interfaceHeight) {
            nbMonthRow = 3;
        } else {
            nbMonthRow = 4;
        }

        var nbMonthCol = 12 / nbMonthRow;

        var margin = 20;
        this.colSize = (interfaceWidth - (nbMonthRow + 1) * margin) / nbMonthRow;
        this.rowSize = (interfaceHeight - (nbMonthCol + 1) * margin) / nbMonthCol;

        var monthSize = {
            width: this.colSize,
            height: this.rowSize,
            margin: margin,
            nbMonthRow: nbMonthRow,
            nbMonthCol: nbMonthCol
        };

        this.yearDrawYear(this.currentDate, monthSize, this.daysOfWeek);

        this.svgRoot.call(d3.drag()
            .on('start', function () {
                if(me.svgRoot.attr('posX') != undefined) {
                    me.__origin__ = parseInt(me.svgRoot.attr('posX'));
                } else {
                    me.__origin__ = 0;
                }
            })
            .on('drag', function () {
                if (d3.event.dx !== undefined) {
                    var newX = me.__origin__ += parseInt(d3.event.dx);
                    me.svgRoot.attr('transform', 'translate(' + newX + ',' + 0 + ')');
                    me.svgRoot.attr('posX', newX);
                }
            })
            .on('end', function() {
                let eventPosX = parseInt(d3.event.dx);
                if (eventPosX - me.__origin__ < -60) {
                    //We call for previous Year;
                    me.svgRoot.transition()
                        .attr('transform', 'translate(' + interfaceWidth + ',0)')
                        .on('end', function () {
                            me.currentDate.subtract(1, 'years');
                            me.yearDrawYear(me.currentDate, monthSize, me.daysOfWeek);
                        });
                } else if (eventPosX - me.__origin__ > 60) {
                    //We call for next Year;
                    me.svgRoot.transition()
                        .attr('transform', 'translate(' + (-interfaceWidth) + ',0)')
                        .on('end', function () {
                            me.currentDate.add(1, 'years');
                            me.yearDrawYear(me.currentDate, monthSize, me.daysOfWeek);
                        });
                } else {
                    me.svgRoot.transition()
                            .attr('transform', 'translate(0,0)');
                    me.svgRoot.attr('posX', 0);
                }
                delete me.__origin__;
            }));

            let zoomBehavior = d3.zoom();
            zoomBehavior.on("zoom", function() {
                //computing the day on which we zoomed
                let sourceEvent = d3.event.sourceEvent;
                if(sourceEvent !== undefined) {
                    if(sourceEvent.targetTouches !== undefined && sourceEvent.targetTouches.length > 0) {
                        sourceEvent = sourceEvent.targetTouches[sourceEvent.targetTouches.length - 1];
                    }
                    let column = Math.floor(sourceEvent.pageX / me.colSize);
                    let row = Math.floor((sourceEvent.pageY - me.config.headerHeight) / me.rowSize);
                    let zoomDate = me.currentDate.date(1).month(row*3 + column);
                    let zoomValue = d3.zoomTransform(this).k;
                    let eventArgs = [zoomValue >= me.__zoomValue__ ? 'in': 'out', zoomDate];
                    me.__zoomValue__ = zoomValue;
                    me.calendarInterface.triggerEvent('interfaceZoomed', eventArgs);
                }
            });

            this.svgRoot.call(zoomBehavior);
    }

    yearDrawYear(currentDate, sizeConfig, daysOfWeek) {
        this.calendarInterface.triggerEvent('yearChanged', [currentDate.year()]);
        this.svgRoot.selectAll('*').remove();
        this.svgRoot.attr('transform', 'translate(0,0)');
        this.svgRoot.attr('posX', '0');
        this.__origin__ = 0;
        this.svgRoot.append('rect')
                .attr('width', this.calendarInterface.getWidth())
                .attr('height', this.calendarInterface.getHeight())
                .attr('fill', 'white');

        for (var i = -12; i < 24; i++) {
            let offset = i < 0 ? -1 : i > 11 ? 1 : 0;
            this.yearDrawMonth(Math.abs(i) % 12, currentDate.year() + offset, sizeConfig, daysOfWeek, offset);
        }
    }

    yearDrawMonth(month, year, sizeConfig, dayConfig, offset) {
        var today = moment();
        var startDate = moment().month(month).year(year).date(1);
        var startWeekday = startDate.isoWeekday();
        var endDate = moment(startDate).endOf('month').date();
        //We now know that the day will be between 1 and <endDate> and that the month starts a <startWeekday>

        var row = Math.floor(month / sizeConfig.nbMonthRow); //0,1,2 -> 0 for 3 months per row, etc..
        var col = month % sizeConfig.nbMonthRow; //0 first col, 1 second, etc..

        var startPos = {
            x: (col * (sizeConfig.width + sizeConfig.margin)) + sizeConfig.margin + (offset * this.calendarInterface.getWidth()),
            y: (row * (sizeConfig.height + sizeConfig.margin)) + sizeConfig.margin
        };

        var monthInnerRowSize = sizeConfig.height / 9; //At most 6 rows for 1month + days row + 2 for the title
        var halfMonthInnerRowSize = monthInnerRowSize / 2;
        var monthInnerColSize = sizeConfig.width / 7; //7days in a month
        var halfMonthInnerColSize = monthInnerColSize / 2;

        var currentMonth = this.svgRoot.append('g')
                .attr('transform', 'translate(' + startPos.x + ',' + startPos.y + ')');

        currentMonth.append('text')
                .attr('x', sizeConfig.width / 2)
                .attr('y', monthInnerRowSize)
                .attr('font-family', 'sans-serif')
                .attr('font-size', (monthInnerRowSize) + 'px')
                .attr('text-anchor', 'middle')
                .attr('class', 'month-title')
                .text(startDate.format('MMMM'));
        currentMonth.append('line')
                .attr('x1', 0)
                .attr('x2', sizeConfig.width)
                .attr('y1', 2 * monthInnerRowSize)
                .attr('y2', 2 * monthInnerRowSize)
                .attr('class', 'calendar-year-month-underline');

        var rowBase = 2 * monthInnerRowSize + halfMonthInnerRowSize;

        for (var i = 0; i < 7; i++) {
            currentMonth.append('text')
                    .attr('x', i * monthInnerColSize + halfMonthInnerColSize)
                    .attr('y', rowBase)
                    .attr('font-family', 'sans-serif')
                    .attr('font-size', (halfMonthInnerRowSize) + 'px')
                    .attr('text-anchor', 'middle')
                    .attr('class', dayConfig[i].class)
                    .text(dayConfig[i].name);
        }

        //drawing days
        rowBase += monthInnerRowSize;
        for (var index = 1; index <= endDate; index++) {
            var workClass = dayConfig[startWeekday - 1].class;
            var colBase = (startWeekday - 1) * monthInnerColSize + halfMonthInnerColSize;
            if (today.diff(startDate, 'days') === 0 && today.date() === startDate.date()) {
                //It's today! we've to show it
                currentMonth.append('rect')
                        .attr('x', colBase - halfMonthInnerColSize)
                        .attr('y', rowBase - halfMonthInnerRowSize)
                        .attr('width', monthInnerColSize)
                        .attr('height', halfMonthInnerRowSize + 2)
                        .attr('class', 'today-marker');
                workClass = workClass + ' today-date';
            }
            currentMonth.append('text')
                    .attr('x', colBase)
                    .attr('y', rowBase)
                    .attr('font-family', 'sans-serif')
                    .attr('font-size', (halfMonthInnerRowSize) + 'px')
                    .attr('text-anchor', 'middle')
                    .attr('class', workClass)
                    .text(index);

            if (startWeekday === 7) {
                startWeekday = 1;
                rowBase += monthInnerRowSize;
            } else {
                startWeekday++;
            }
            startDate.add(1, 'days');
        }
    }

    yearOnMonthClicked(month, year) {
        let firstOfMonth = moment().set({
            'year': year,
            'month': month,
            'date': 1,
            'hour': 12,
            'minute': 0,
            'second': 0,
            'millisecond': 0
        });

        let eventArgs = ['in', firstOfMonth];
        this.calendarInterface.triggerEvent('interfaceZoomed', eventArgs);
    }
}

export { SAICalendarYearsRenderer };