import { SAIEventCalendar, SAICalendarConfig } from './sai-event-calendar';
import { SAICalendarModeRenderer } from './sai-event-calendar-mode';
import { SAIEvent, SAIEventPosition } from './sai-event-core';
import { SAITimePeriod } from "./sai-event-main";

var isMobile = require('ismobilejs');
import * as d3 from 'd3';
import * as moment from 'moment';
import { SAIEventInterfaceDisplayMode } from './sai-event-interface';
const _ = require('lodash');

type DrawableEvent = {
    rects: Array<any>,
    texts: Array<any>,
    event: SAIEvent,
    isFullDay: boolean,
    isOneDay: boolean,
    position: -1
};

class SAICalendarMonthRenderer extends SAICalendarModeRenderer {
    private daysOfWeek: Array<{ name: string, class: string}>;
    private daysMap: { [key: string] : {
            usedSlots : Array<{ num : number }>,
            events: Array<SAIEvent>,
            extraText: any
        }
    };

    private fullDayTouchMap: {};
    private basePosMap : {};

    private calendarInterface: SAIEventCalendar;

    private rootElement: any;
    private svgRoot: any;
    private daysGrid: any;
    private newEventMarker: any;
    private dropShadow: any;
    private daysHeaders: any;

    private eventList: any;

    private colSize: number;
    private rowSize: number;

    private __origin__ : any;
    private __zoomValue__ : number;

    private touchedEventStart: SAIEvent;
    private mousedEventDown: SAIEvent;
    private EVENT_HEIGHT: number;
    private LETTER_MEAN_EVENT_WIDTH: number;
    private LETTER_MEAN_25_WIDTH: number;
    private LETTER_MEAN_40_WIDTH: number;

    private renderIteration: number;

    private calendarOptionsDiv: any;
    private currentMonthDisplay: any;
    private selectedEvent: any;
    private initialTranslateValues: Array<number>;

    private calendarOptionHeight = 40;

    private displayedEventListDate: moment.Moment;

    constructor(options: { currentDate: moment.Moment, config: SAICalendarConfig, calendarInterface: SAIEventCalendar, rootElement: any}) {
        super(options);
        this.currentDate = moment(options.currentDate).date(1);
        this.__zoomValue__ = 1;
        this.calendarInterface = options.calendarInterface;
        this.rootElement = options.rootElement;
        let namesDate = moment(this.currentDate);
        this.daysOfWeek = [{name: namesDate.isoWeekday(1).locale(this.config.locale).format(this.config.headerFormat), class: 'working-day'},
            {name: namesDate.isoWeekday(2).locale(this.config.locale).format(this.config.headerFormat), class: 'working-day'},
            {name: namesDate.isoWeekday(3).locale(this.config.locale).format(this.config.headerFormat), class: 'working-day'},
            {name: namesDate.isoWeekday(4).locale(this.config.locale).format(this.config.headerFormat), class: 'working-day'},
            {name: namesDate.isoWeekday(5).locale(this.config.locale).format(this.config.headerFormat), class: 'working-day'},
            {name: namesDate.isoWeekday(6).locale(this.config.locale).format(this.config.headerFormat), class: 'non-working-day'},
            {name: namesDate.isoWeekday(7).locale(this.config.locale).format(this.config.headerFormat), class: 'non-working-day'}
        ];

        this.EVENT_HEIGHT = 80;
    }

    public renderInterface() : void {
        var me = this;
        let renderIteration = this.calendarInterface.getNextRenderIteration();
        this.currentDate = this.currentDate.date(1);

        let interfaceWidth = this.calendarInterface.getWidth();
        let interfaceHeight = this.calendarInterface.getHeight();

        let calendarRoot = this.rootElement.append('div')
            .attr('class','calendar calendar-month');

        if(this.config.showTopMenu) {
            this.calendarOptionsDiv = calendarRoot.append('div')
                .attr('class','calendar-options');
            this.buildCalendarOptions();
        }

        this.svgRoot = calendarRoot.append('svg');
        if(this.config.showTopMenu) {
            interfaceHeight -= $(this.svgRoot.node()).offset().top;
        }

        this.svgRoot
            .attr('width', interfaceWidth)
            .attr('height', interfaceHeight)
            .attr('class', 'calendar calendar-month');

        this.addUnselectEventHandler(this.svgRoot);

        //We build a fake title to know the max length of a char. It'll be then
        //used to quickly compute maximum text size without having to compute them
        //live. Live computation is very expensive and breaks the normal flow
        //of the browser rendering
        let fakeComputeText = this.svgRoot.append('text')
            .attr('font-family', 'sans-serif')
            .attr('font-size', Math.ceil(this.config.eventSize/2)+'px')
            .attr('text-anchor', 'start')
            .attr('class', 'events-marker-text invisible-computation-text')
            .text('M');
        this.LETTER_MEAN_EVENT_WIDTH = fakeComputeText.node().getBBox().width;
        fakeComputeText.attr('font-size', 25);
        this.LETTER_MEAN_25_WIDTH = fakeComputeText.node().getBBox().width;
        fakeComputeText.attr('font-size', 40);
        this.LETTER_MEAN_40_WIDTH = fakeComputeText.node().getBBox().width;
        fakeComputeText.remove();

        var headerHeight = this.config.headerHeight;
        this.colSize = interfaceWidth / 7;
        this.rowSize = (interfaceHeight - headerHeight) / 6;

        this.daysGrid = this.svgRoot.append('g')
        .attr('class', 'calendar-grid')
        .attr('posY', headerHeight)
        .attr('transform', 'translate(0,' + headerHeight + ')')
        .on('click', this.monthOnDayClick.bind(this, headerHeight));

        this.monthDrawGrid(this.daysOfWeek, this.colSize, this.rowSize);
        this.monthRequestNewData();
        this.daysHeaders = this.svgRoot.append('g')
                .attr('class', 'calendar-header');

        this.daysHeaders.append('rect')
                .attr('width', this.calendarInterface.getWidth())
                .attr('height', headerHeight)
                .attr('class', 'header-background');
        this.daysHeaders.daysLabels = this.daysHeaders.selectAll('text')
                .data(this.daysOfWeek)
                .enter()
                .append('text');

        this.daysHeaders.daysLabels.attr('x', function (d, index) {
                if(me.config.headerAlignment === 'end'){
                    return (index+1) * me.colSize - 5;
                }else if(me.config.headerAlignment === 'start'){
                    return index * me.colSize + 5;
                }else{
                    return (index + 1) * me.colSize - me.colSize / 2;
                }

            })
            .attr('y', '22')
            .text(function (d) {
                let finalText = d.name.replace(/\\./g, '')
                return finalText.substring(0,1).toUpperCase() + finalText.substring(1);
            })
            .attr('text-anchor', me.config.headerAlignment)
            .attr('class', function (d) {
                return d.class+' headerDay';
            });

        this.dropShadow = this.svgRoot.append('filter')
                .attr('xmlns', 'http://www.w3.org/2000/svg')
                .attr('id', 'dropshadow'+renderIteration)
                .attr('height', '130%');
        this.dropShadow.append('feGaussianBlur')
                .attr('in', 'SourceAlpha')
                .attr('stdDeviation', '3');
        this.dropShadow.append('feOffset')
                .attr('dx', '2')
                .attr('dy', '2')
                .attr('result', 'offsetblur');
        this.dropShadow.transfer = this.dropShadow.append('feComponentTransfer');
        this.dropShadow.transfer.append('feFuncA')
                .attr('type', 'linear')
                .attr('slope', '0.2');
        this.dropShadow.feMerge = this.dropShadow.append('feMerge');
        this.dropShadow.feMerge.append('feMergeNode');
        this.dropShadow.feMerge.append('feMergeNode')
                .attr('in', 'SourceGraphic');

        if(this.config.displayNewMarker){
            let markerPosY = this.calendarInterface.getHeight() - 60;
            if(this.config.showTopMenu) {
                markerPosY -= $(this.svgRoot.node()).offset().top;
            }
            this.newEventMarker = this.svgRoot.append('g')
                    .attr('transform', 'translate(' + (this.calendarInterface.getWidth() - 80) + ',' + markerPosY + ')')
                    .attr('class', 'calendar-month-add-group');
            this.newEventMarker.append('rect')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 60)
                    .attr('height', 40)
                    .attr('transform', 'skewY(15)')
                    .attr('class', 'calendar-month-add-event')
                    .attr('filter', 'url(#dropshadow'+renderIteration+')');
            this.newEventMarker.append('path')
                    .attr('class', 'calendar-month-add-event-plus')
                    .attr('d', 'M' + 27 + ' ' + 17 + ' h7 v7 h7 v7 h-7 v7 h-7 v-7 h-7 v-7 h7 z');
            this.newEventMarker.on('click', this.monthOnNewMarkerClick.bind(this));
        }

        this.setupBehaviors();
    }

    private getClickedDate() : moment.Moment{
        let pageX = d3.event.pageX, pageY = d3.event.pageY;
        if(d3.event.changedTouches !== undefined && d3.event.changedTouches.length > 0) {
            pageX = d3.event.changedTouches[0].pageX, pageY = d3.event.changedTouches[0].pageY;
        }

        let calOffset = $(this.svgRoot.node()).offset();
        pageX = pageX - calOffset.left;
        pageY = pageY - calOffset.top;

        let column = Math.floor(pageX / this.colSize);
        let row = Math.floor((pageY - this.config.headerHeight) / this.rowSize);
        let firstDayStart = moment(this.currentDate);
        if (firstDayStart.isoWeekday() !== 1) {
            firstDayStart.subtract(firstDayStart.isoWeekday() - 1, 'days');
        }
        return firstDayStart.add(row*7 + column, 'days');
    }

    private monthOnDayClick(): void{
        let startDay = this.getClickedDate();
        this.calendarInterface.triggerEvent('daySelected', [startDay.date(), startDay.month(), startDay.year()]);
    }

    monthOnNewMarkerClick(atDate? : moment.Moment) {
        this.calendarInterface.triggerEvent('newEvent', [atDate || moment()]);
    }

    monthRequestNewData() {
        let startDate = this.currentDate;

        startDate = moment(this.currentDate).date(1).startOf('day');
        if (startDate.isoWeekday() !== 1) {
            startDate.subtract(startDate.isoWeekday() - 1, 'days');
        }
        let endDate = moment(startDate).add(43, 'days');

        this.calendarInterface.setStart(startDate);
        this.calendarInterface.setEnd(endDate);

        let fetchPeriod : SAITimePeriod = new SAITimePeriod(startDate, endDate);
        this.calendarInterface.getManager().changePeriod(fetchPeriod);
    }

    monthDrawGrid(daysOfWeek, colSize, rowSize) {
        var me = this;

        var xPos;
        var yPos;

        this.calendarInterface.triggerEvent('monthChanged', [this.currentDate.month(), this.currentDate.year()]);
        this.daysGrid.selectAll('*').remove();
        this.daysGrid.attr('transform', 'translate(0,'+this.config.headerHeight+')')
                .attr('posY', this.config.headerHeight);


        this.daysGrid.background = this.daysGrid.append('rect')
                .attr('x', 0)
                .attr('y', -7 * rowSize)
                .attr('width', this.calendarInterface.getWidth())
                .attr('height', 13 * rowSize)
                .attr('fill', 'white');

        var firstDayOfMonth = moment(this.currentDate).date(1);
        //endOf mutates the value thus we clone before usage
        var lastDayOfMonth = moment(firstDayOfMonth).endOf('month');

        var currentDay = this.currentDate.isoWeekday();
        //All previous hidden cells + those up to the one corresponding to
        //the start of month
        var cellStartNum = 7 * 7 + currentDay - 1;
        var cellEndNum = cellStartNum + lastDayOfMonth.date();

        var totalCells = 7 * 20;

        this.daysGrid.hiderects = this.daysGrid.append('g')
                .attr('class', 'calendar-hide-rects');

        //rectangle for previous and next monthes
        this.daysGrid.hiderects.append('rect')
                .attr('x', 0)
                .attr('y', -7 * rowSize)
                .attr('width', this.calendarInterface.getWidth())
                .attr('height', 7 * rowSize);
        this.daysGrid.hiderects.append('rect')
                .attr('x', 0)
                .attr('y', 6 * rowSize)
                .attr('width', this.calendarInterface.getWidth())
                .attr('height', 7 * rowSize);
        //if the month doens't start a monday, we'll need an extra rec
        if (firstDayOfMonth.isoWeekday() !== 1) {
            this.daysGrid.hiderects.append('rect')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', (firstDayOfMonth.isoWeekday() - 1) * colSize)
                    .attr('height', rowSize);
        }
        //Checking how much cells take the month as there might be some
        //extra cells from next month to cover on two rows
        var numRemainingCells = 42 - (lastDayOfMonth.diff(firstDayOfMonth, 'days') + firstDayOfMonth.isoWeekday());

        if (numRemainingCells > 7) {
            this.daysGrid.hiderects.append('rect')
                    .attr('x', lastDayOfMonth.isoWeekday() * colSize)
                    .attr('y', 4 * rowSize)
                    .attr('width', (7 - lastDayOfMonth.isoWeekday()) * colSize)
                    .attr('height', rowSize);
            this.daysGrid.hiderects.append('rect')
                    .attr('x', 0)
                    .attr('y', 5 * rowSize)
                    .attr('width', 7 * colSize)
                    .attr('height', rowSize);
        } else if (numRemainingCells > 0) {
            this.daysGrid.hiderects.append('rect')
                    .attr('x', (lastDayOfMonth.isoWeekday() % 7) * colSize)
                    .attr('y', 5 * rowSize)
                    .attr('width', (7 - (lastDayOfMonth.isoWeekday() % 7)) * colSize)
                    .attr('height', rowSize);
        }

        this.daysGrid.lines = this.daysGrid.append('g')
                .attr('class', 'calendar-lines');

        for (var vertId = 0; vertId < 6; vertId++) {
            var x = (vertId + 1) * colSize;
            this.daysGrid.lines.append('line')
                    .attr('x1', x)
                    .attr('y1', -7 * rowSize)
                    .attr('x2', x)
                    .attr('y2', 13 * rowSize)
                    .attr('class', 'calendar-line');
        }

        for (var horId = -7; horId < 14; horId++) {
            var y = horId * rowSize;
            this.daysGrid.lines.append('line')
                    .attr('x1', 0)
                    .attr('y1', y)
                    .attr('x2', this.calendarInterface.getWidth())
                    .attr('y2', y)
                    .attr('class', 'calendar-line');
        }

        var today = moment();

        this.daysGrid.numbers = this.daysGrid.append('g')
                .attr('class', 'calendar-numbers');

        var dayNumberXPosition = function(d){
            if(me.config.dayNumberAlignment.indexOf('right') > -1){
                return xPos + colSize - 5;
            }else{
                return xPos + 5;
            }
        };

        var dayNumberYPosition = function (d) {
            if (me.config.dayNumberAlignment.indexOf('top') > -1){
                return yPos + 15;
            }else{
                return yPos + rowSize - 15;
            }
        };

        for (var cellId = 0; cellId < totalCells; cellId++) {
            var diff = cellId - cellStartNum;
            var currentDrawingDate = moment(this.currentDate).add(diff, 'days');
            var currentIsoWeekday = currentDrawingDate.isoWeekday();
            var workingDayClass = daysOfWeek[currentIsoWeekday - 1].class;
            var outsideMonth = cellId < cellStartNum || cellId >= cellEndNum;
            workingDayClass = workingDayClass + (outsideMonth ? ' calendar-day-outside-month' : '');

            xPos = (currentIsoWeekday - 1) * colSize;
            if (cellId % 7 === 0) {
                yPos = (Math.ceil(cellId / 7)) * rowSize - 7 * rowSize;
            } else {
                yPos = (Math.ceil(cellId / 7) - 1) * rowSize - 7 * rowSize;
            }

            if (today.diff(currentDrawingDate, 'days') === 0 && today.date() === currentDrawingDate.date()) {
                //It's today! we've to show it
                this.daysGrid.todayRec = this.daysGrid.numbers.append('g');
                this.daysGrid.todayRec
                        .attr('transform', 'translate(' + ( me.config.dayNumberAlignment.indexOf('right') > -1 ? ((xPos+colSize-25) + ',' + (yPos+5)) : (xPos + ',' + yPos) ) + ')')
                        .attr('class', 'today-marker');
                this.daysGrid.todayRec.append('rect')
                        .attr('x', 0)
                        .attr('y', 0)
                        .attr('width', 25)
                        .attr('height', 25)
                        .attr('transform', me.config.dayNumberAlignment.indexOf('right') > -1 ?'skewY(-15)':'skewY(15)')
                        .attr('class', 'today-marker');

                workingDayClass = workingDayClass + ' today-date';
            }

            this.daysGrid.numbers.append('text')
                    .attr('x', dayNumberXPosition)
                    .attr('y', dayNumberYPosition)
                    .text(currentDrawingDate.date())
                    .attr('font-family', 'sans-serif')
                    .attr('font-size', '15px')
                    .attr('text-anchor', me.config.dayNumberAlignment.indexOf('right') > -1? 'end' : 'start')
                    .attr('class', workingDayClass);
        }

        this.daysGrid.eventsGroup = this.daysGrid.append('g')
                .attr('class', 'calendar-events');
        this.buildCurrentMonth();
    }

    private getDrawingSlot(currentEvent: SAIEvent, basePosMap: {[key: string] : {x: number, y: number}}, maxDrawnEventsPerCell: number) {
        let startDate = currentEvent.getStart();
        let endDate = currentEvent.getEnd();

        //Check if the event is a full day event. It means XX/YY/ZZZZ 00:00 to some other 00:00
        let isFullDay = moment(startDate).startOf('day').isSame(startDate) && moment(endDate).startOf('day').isSame(endDate);

        //Checking if the event is over multiple days or not
        //A multiple day event is an event that spans at least on 1ms on
        //another day
        let todayStart = startDate.format('YYYY-MM-DD');
        let todayCheckEnd = moment(endDate).subtract(1, 'millisecond').format('YYYY-MM-DD');
        let isOneDay = todayStart === todayCheckEnd;

        //Check of the first available slot
        let requestSlot = 0;
        let impossibleSlot = false;
        while(requestSlot < maxDrawnEventsPerCell && !impossibleSlot) {
            let availableSlot = true;
            let counterDate = moment(startDate).startOf('day');
            //iteration over all buckets to check if the slot is available
            while(!counterDate.isAfter(endDate)) {
                let bucketId = counterDate.format('YYYY-MM-DD');
                //Bucket building if not exists
                if (this.daysMap[bucketId] === undefined) {
                    this.daysMap[bucketId] = {
                        usedSlots: [],
                        events: [],
                        extraText : undefined
                    };
                    var diff = counterDate.diff(moment(this.calendarInterface.getStart()).startOf('day'), 'days');
                    this.basePosMap[bucketId] = {
                        x: (counterDate.isoWeekday() - 1) * this.colSize,
                        y: Math.floor(diff / 7) * this.rowSize
                    };
                }

                if(_.find(this.daysMap[bucketId].events, function(o){ return o === currentEvent;}) === undefined) {
                    this.daysMap[bucketId].events.push(currentEvent);
                }

                //If we already used all slots we won't find an available slot
                if(this.daysMap[bucketId].usedSlots.length == maxDrawnEventsPerCell) {
                    impossibleSlot = true;
                }
                //If the currently requested slot is taken
                if(_.find(this.daysMap[bucketId].usedSlots, { num : requestSlot }) !== undefined) {
                    availableSlot = false;
                }
                counterDate.add(1, 'day');
            }
            //If the slot is fully available we reserve it
            if(!impossibleSlot && availableSlot) {
                let counterDate = moment(startDate).startOf('day');
                //iteration over all buckets to check if the slot is available
                while(counterDate.isBefore(endDate)) {
                    let bucketId = counterDate.format('YYYY-MM-DD');
                    this.daysMap[bucketId].usedSlots.push({ num: requestSlot });
                    counterDate.add(1, 'day');
                }
                return requestSlot;
            }
            requestSlot++;
        }

        return -1;
    }

    private onTouchEventStart(saiEvent: SAIEvent) {
        this.touchedEventStart = saiEvent;
    }

    private onTouchEventMove(saiEvent: SAIEvent) {
        this.touchedEventStart = undefined;
    }

    private onTouchEventEnd(saiEvent: SAIEvent) {
        if(this.touchedEventStart === saiEvent) {
            d3.event.stopPropagation();
            d3.event.preventDefault();
            this.displayEventList(this.getClickedDate());
        }

        this.touchedEventStart = undefined;
    }

    private onMouseEventClick(event: SAIEvent, svgElement) :void {
        d3.event.stopPropagation();
        d3.event.preventDefault();
        this.selectEvent(event, svgElement);
    }

    selectEvent(currentEvent, svgElement) {
        if(this.selectedEvent && this.selectedEvent.id === currentEvent.getId()) {
            // do nothing
        } else {
            if(this.selectedEvent) {
                let id = $(this.selectedEvent.svg.node()).data('id');
                $(this.svgRoot.node()).find('rect[data-id='+id+']').removeClass('selected');
                $(this.svgRoot.node()).find('text[data-id='+id+']').removeClass('selected');
            } else {
                $(this.svgRoot.node()).addClass('event-selected');
            }
            this.selectedEvent = {id: currentEvent.getId(), svg: svgElement, start: currentEvent.getStart(), end: currentEvent.getEnd()};
            this.calendarInterface.triggerEvent('eventSelected', [currentEvent]);
            let id = $(this.selectedEvent.svg.node()).data('id');
            $(this.svgRoot.node()).find('rect[data-id='+id+']').addClass('selected');
            $(this.svgRoot.node()).find('text[data-id='+id+']').addClass('selected');
        }
    }

    addUnselectEventHandler(svgElement: any, removeTime?: boolean) {
        if(removeTime === undefined) {
            removeTime = false;
        }
        let me = this;
        svgElement.on('click', function() {
            let src = svgElement.node().parentElement;
            if(me.selectedEvent && !(src.classList.contains('calendar-day-event') || src.classList.contains('calendar-fullday-event'))) {
                d3.event.stopPropagation();
                d3.event.preventDefault();
                me.deselectEvent();
            }
        });
    }

    deselectEvent() {
        let id = $(this.selectedEvent.svg.node()).data('id');
        $(this.svgRoot.node()).find('rect[data-id='+id+']').removeClass('selected');
        $(this.svgRoot.node()).find('text[data-id='+id+']').removeClass('selected');
        $(this.svgRoot.node()).removeClass('event-selected');
        this.selectedEvent = undefined;
        this.calendarInterface.triggerEvent('eventUnselected',null);
    }

    onDblClickEventHandler(currentEvent) {
        this.calendarInterface.triggerEvent('openEvent', [currentEvent]);
        d3.event.stopPropagation();
    }

    private displayEventList(atDate : moment.Moment) {
        let interfWidth = this.calendarInterface.getWidth();
        let interfHeight = this.calendarInterface.getHeight() - $(this.svgRoot.node()).offset().top;

        let renderIteration = this.calendarInterface.getCurrentRenderIteration();

        if(this.eventList) {
            this.eventList.classed('list-visible', true);
            let rootContent = this.eventList.select('g.event-list-content').remove();
        } else {
            let me = this;
            this.eventList = this.svgRoot.append('g')
                .attr('class', 'event-list-container list-visible');
            /*
             <filter id="f4" x="0" y="0" width="200%" height="200%">
                <feOffset result="offOut" in="SourceGraphic" dx="20" dy="20" />
                <feColorMatrix result="matrixOut" in="offOut" type="matrix"
                values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0" />
                <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="10" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            */
            let shadow = this.eventList.append('filter').attr('id','card-drop-shadow'+renderIteration).attr('x', '0').attr('y', '0').attr('width', '200%').attr('height', '200%');
            shadow.append('feOffset').attr('result','offOut').attr('in','SourceGraphic').attr('dx', '5').attr('dy','5');
            shadow.append('feColorMatrix').attr('result','matrixOut').attr('in','offOut').attr('type','matrix').attr('values','0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0');
            shadow.append('feGaussianBlur').attr('result','blurOut').attr('in','matrixOut').attr('stdDeviation','10');
            shadow.append('feBlend').attr('in', 'SourceGraphic').attr('in2', 'blurOut').attr('mode', 'normal');
            let eventListMask = this.eventList.append('rect')
                .attr('class', 'event-list-mask mask-visible')
                .attr('width', interfWidth)
                .attr('height', interfHeight);

            eventListMask.on('click', function() {
                me.eventList.classed('list-visible', false);
                me.displayedEventListDate = null;
            });
        }

        let eventRecWidth = Math.max(interfWidth / 2, Math.min(500, interfWidth));
        let eventRecHeight = Math.min(interfHeight - 30, 800);
        this.createEventCard(atDate, interfWidth, interfHeight, (interfWidth - eventRecWidth)/2, (interfHeight - eventRecHeight) / 2, 0);
        this.displayedEventListDate = atDate;
    }

    private sizeText(node, title, maxSize, baseLetter?: number){
        baseLetter = baseLetter || this.LETTER_MEAN_EVENT_WIDTH;
        //Adding 2 to the normal computation as we know that we're using at least
        //one ':', a ' ' and 4 numbers which are small chars compared to an 'M'
        node.text(title.substring(0, Math.ceil(maxSize/baseLetter) + 2));
    }

    private createEventCard(atDate: moment.Moment, interfWidth: number, interfHeight: number, xPos: number, yPos: number, cardId: number) {
        let eventRecWidth = Math.max(interfWidth / 2, Math.min(500, interfWidth));
        let eventRecHeight = Math.min(interfHeight - 30, 800);
        let centering = 'translate(' + xPos + ',' + yPos + ')';

        let renderIteration = this.calendarInterface.getCurrentRenderIteration();

        let eventListContent = this.eventList.append('g')
                .attr('class', 'event-list-content')
                .attr('style', 'filter:url(#card-drop-shadow'+renderIteration+')')
                .attr('transform', centering);

        let eventRec = eventListContent.append('rect')
            .attr('width', eventRecWidth)
            .attr('height', eventRecHeight)
            .attr('class', 'event-list-holder');

            let list = eventListContent.append('g')
            .attr('class', 'event-list-scrollable');

        let eventBucket = this.daysMap[atDate.format('YYYY-MM-DD')];
        let backrecArray = [];
        let eventYStart = 120;
        let totalEventsHeight = 0;
        for(let i  = 0; i < eventBucket.events.length; i++) {
            let curEv = eventBucket.events[i];
            let hasDescription = curEv.getDescription() != undefined;
            let curEventHeight = hasDescription ? this.EVENT_HEIGHT + 10 : this.EVENT_HEIGHT;
            totalEventsHeight += curEventHeight;
            let startD = curEv.getStart();
            let endD = curEv.getEnd();
            let resetStartD = moment(startD).startOf('day');
            let resetEndD = moment(endD).startOf('day');

            //Check if the event is a full day event. It means XX/YY/ZZZZ 00:00 to some other 00:00
            let isFullDay = startD.isSame(resetStartD) && endD.isSame(resetEndD);

            //Checking if the event is over multiple days or not
            //A multiple day event is an event that spans at least on 1ms on
            //another day
            let curDateString = resetStartD.format('YYYY-MM-DD');
            let todayCheckEnd = moment(endD).subtract(1, 'millisecond').format('YYYY-MM-DD');
            let isOneDay = curDateString === todayCheckEnd;

            let eventbackgroundRect = list.append('rect')
                .attr('x', 20)
                .attr('y', eventYStart)
                .attr('width', eventRecWidth - 40)
                .attr('height',curEventHeight)
                .attr('data-index', i);
            backrecArray.push(eventbackgroundRect);

            list.append('rect')
                .attr('x', 30)
                .attr('y', eventYStart + 5)
                .attr('width', 10)
                .attr('height', 10)
                .attr('fill', curEv.getColor())
                .attr('data-index', i);
            let dytext = (eventYStart + 20);
            let title = curEv.getTitle();
            let titleNode = list.append('text')
                .attr('x', 60)
                .attr('y', dytext)
                .attr('class', 'event-list-content-event-text')
                .attr('font-size', 25)
                .attr('data-index', i);
            this.sizeText(titleNode, title, eventRecWidth, this.LETTER_MEAN_25_WIDTH);

            if(curEv.getDescription() !== undefined) {
                let dysubtitle = (eventYStart + 45);
                let subtitle = list.append('text')
                    .attr('x', 60)
                    .attr('y', dysubtitle)
                    .attr('class', 'event-list-content-event-text')
                    .attr('font-size', 20)
                    .attr('data-index', i);
                this.sizeText(subtitle, curEv.getDescription(), eventRecWidth, this.LETTER_MEAN_EVENT_WIDTH);
            }

            let subtext;
            if(isFullDay) {
                subtext = 'Journée entière';
            } else if(isOneDay) {
                subtext = startD.format('HH:mm') + '-' + endD.format('HH:mm');
            } else if(startD.isAfter(atDate)) {
                subtext = startD.format('HH:mm');
            } else {
                subtext = 'Jusqu\'à ' + endD.format('HH:mm');
            }

            let dysub = hasDescription ? (eventYStart + 65) : (eventYStart + 45);
            list.append('text')
            .attr('class', 'event-list-content-event-subtext')
            .attr('x', 60)
            .attr('y', dysub)
            .attr('font-size', 18)
            .text(subtext)
            .attr('data-index', i);

            eventYStart = eventYStart + curEventHeight;
        }

        let topEventRec = eventListContent.append('rect')
            .attr('width', eventRecWidth)
            .attr('height', 120)
            .attr('fill', 'white')
            .attr('class', 'event-card-mask')
            .attr('x', 0)
            .attr('y', 0);

        let bottomEventRec = eventListContent.append('rect')
            .attr('width', eventRecWidth)
            .attr('height', this.EVENT_HEIGHT)
            .attr('fill', 'white')
            .attr('class', 'event-card-mask')
            .attr('x', 0)
            .attr('y', eventRecHeight - this.EVENT_HEIGHT);

        let eventListDate = eventListContent.append('text')
        .attr('transform', 'translate(20,50)')
        .attr('font-size', 40)
        .attr('class', 'event-list-date')
        .text(atDate.date());

        let eventListDay = eventListContent.append('text')
        .attr('transform', 'translate(20,80)')
        .attr('font-size', 20)
        .attr('class', 'event-list-day')
        .text(atDate.locale(this.config.locale).format('dddd'));

        let eventListSeparator = eventListContent.append('line')
        .attr('x1', 10)
        .attr('y1', 100)
        .attr('x2', eventRecWidth - 20)
        .attr('y2', 100)
        .attr('class', 'event-list-separator');

        let newCardEventMarker = eventListContent.append('g')
                .attr('transform', 'translate(' + (eventRecWidth - this.EVENT_HEIGHT) + ',' + (eventRecHeight - this.EVENT_HEIGHT) + ')')
                .attr('class', 'calendar-month-add-group');
        newCardEventMarker.append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 60)
                .attr('height', 40)
                .attr('transform', 'skewY(15)')
                .attr('class', 'calendar-month-add-event')
                .attr('filter', 'url(#dropshadow'+renderIteration+')');
        newCardEventMarker.append('path')
                .attr('class', 'calendar-month-add-event-plus')
                .attr('d', 'M' + 27 + ' ' + 17 + ' h7 v7 h7 v7 h-7 v7 h-7 v-7 h-7 v-7 h7 z');
        newCardEventMarker.on('click', this.monthOnNewMarkerClick.bind(this, atDate));

        //The list can be dragged upward or downward
        let listOrigin = { value: undefined, initial: undefined, delta: 0 };
        let diffLimit = eventBucket.events.length*this.EVENT_HEIGHT < eventRecHeight - 200 ? 0 : eventRecHeight - 200 - totalEventsHeight;
        list.on('touchstart', this.onEventListStart.bind(this, list, listOrigin), { passive: true});
        list.on('touchmove', this.onEventListMove.bind(this, list, listOrigin, diffLimit, eventRecHeight))
        list.on('touchend', this.onEventListEnd.bind(this, list, listOrigin, eventBucket, backrecArray));
        list.on('mousedown', this.onEventListStart.bind(this, list, listOrigin));
        list.on('mousemove', this.onEventListMove.bind(this, list, listOrigin, diffLimit, eventRecHeight))
        list.on('mouseup', this.onEventListEnd.bind(this, listOrigin, eventBucket, backrecArray));

        // Get the labels for the card
        this.calendarInterface.getManager().getCardLabels(atDate, this.onCardLabelsReceived.bind(this));

        this.hideBottomCardEvents(list, eventRecHeight);
    }

    onCardLabelsReceived(moment: moment.Moment, labels:any) {
        if(labels.title) {
            let title = this.eventList.select('g').append('text')
            .attr('x', '460')
            .attr('y', '50')
            .attr('font-size', 40)
            .attr('class', 'event-list-title')
            .attr('text-anchor','end');
            this.sizeText(title,labels.title, 600, this.LETTER_MEAN_40_WIDTH);
        }
        if(labels.subTitle) {
            let subtitle = this.eventList.select('g').append('text')
            .attr('x', '460')
            .attr('y', '80')
            .attr('font-size', 25)
            .attr('class', 'event-list-subtitle')
            .attr('text-anchor','end');
            this.sizeText(subtitle,labels.subTitle, 600, this.LETTER_MEAN_25_WIDTH);
        }
    }

    private onEventListStart(list, listOrigin) {
        if(d3.event.touches) {
            listOrigin.value = parseInt(d3.event.touches[0].pageY);
        } else {
            listOrigin.value = parseInt(d3.event.pageY);
        }
        listOrigin.delta = list.attr('posY') !== null ? parseFloat(list.attr('posY')) : 0;
        listOrigin.initial = listOrigin.value;
    }

    private hideBottomCardEvents(list, eventRecHeight) {
        let nbHiddenTop = 0.25;
        let me = this;
        list.selectAll('*').each(function(d, i) {
            let item = d3.select(this);
            let positionY = item.attr('y');
            let topLimit = nbHiddenTop * me.EVENT_HEIGHT;
            let bottomLimit = topLimit + eventRecHeight - 100;
            let elemPos = parseFloat(positionY);
            if(elemPos >= bottomLimit) {
                item.classed('item-masked', true);
            }
        });
    }

    private onEventListMove(list, listOrigin, diffLimit, eventRecHeight) {
        if(listOrigin.value !== undefined) {
            let me = this;
            if(d3.event.touches) {
                listOrigin.value = parseInt(d3.event.touches[0].pageY);
            } else {
                listOrigin.value = parseInt(d3.event.pageY);
            }
            let delta = -(listOrigin.initial - listOrigin.value) + listOrigin.delta;
            if(isNaN(delta)) {
                delta = 0;
            } else if(delta > 0) {
                delta = 0;
            } else if(delta < diffLimit) {
                delta = diffLimit;
            }

            list.attr('transform', 'translate(' + 0 + ',' + delta + ')');
            list.attr('posY', delta);
            //Display or hide of all elements that are outside the list
            //0.25 is some extra margin to make sure no pixel goes outside of the box
            let nbHiddenTop = -(delta / me.EVENT_HEIGHT) + 0.25;

            list.selectAll('*').each(function(d, i) {
                let item = d3.select(this);
                let positionY = item.attr('y');
                let topLimit = nbHiddenTop * me.EVENT_HEIGHT;
                let bottomLimit = topLimit + eventRecHeight - 100;
                let elemPos = parseFloat(positionY);
                if(elemPos <= topLimit || elemPos >= bottomLimit) {
                    item.classed('item-masked', true);
                } else {
                    item.classed('item-masked', false);
                }
            });

            //d3.event.preventDefault();
            d3.event.stopPropagation();
        }
    }

    private onEventListEnd(list, listOrigin, eventBucket, backrecArray) {
        if(listOrigin.initial === listOrigin.value) {
            let me = this;
            //it's a click
            d3.event.stopPropagation();
            d3.event.preventDefault();
            let index = parseInt(d3.event.target.attributes['data-index'].value);
            backrecArray[index]
                .transition() // First fade to grey.
                .style("fill", "#e7e7e7")
                .transition() // Then fade to white again.
                .on('end', function() {
                    me.calendarInterface.triggerEvent('eventSelected', [eventBucket.events[index]]);
                    me.eventList.classed('list-visible', false);
                    me.displayedEventListDate = null;
                })
                .style("fill", "#ffffff");
        }
        listOrigin.value = undefined;
    }

    public renderEventsList (events: Array<SAIEvent>) {
        super.renderEventsList(events);
        this.clearFullDayTouchMap();
        this.daysMap = {};

        var eventSize = this.config.eventSize;
        var marginSpace = 15;
        var meanCharSize = 4;
        var nbMaxChar = Math.floor(this.colSize - 10 / meanCharSize);
        var maxDrawnEventsPerCell = Math.max(Math.floor((this.rowSize - marginSpace) / eventSize) - 1, 1);
        this.basePosMap = {};

        let sortedEvents = events.sort((a, b) => {
            //Comparing by date
            let baseCompare = a.getStart().format('YYYY-MM-DD').localeCompare(b.getStart().format('YYYY-MM-DD'));
            if(baseCompare != 0) {
                return baseCompare;
            }

            let fstartD = a.getStart();
            let fendD = a.getEnd();
            let fresetStartD = moment(fstartD).startOf('day');
            let fresetEndD = moment(fendD).startOf('day');
            let ftodayCheckEnd = moment(fendD).subtract(1, 'millisecond').format('YYYY-MM-DD');
            let firstSpan = !fresetStartD.isSame(ftodayCheckEnd);

            let sstartD = b.getStart();
            let sendD = b.getEnd();
            let sresetStartD = moment(sstartD).startOf('day');
            let sresetEndD = moment(sendD).startOf('day');
            let stodayCheckEnd = moment(sendD).subtract(1, 'millisecond').format('YYYY-MM-DD');
            let secondSpan = !sresetStartD.isSame(stodayCheckEnd);

            if(firstSpan && !secondSpan) {
                return -1;
            } else if(!firstSpan && secondSpan) {
                return 1;
            }

            let firstisFullDay = fstartD.isSame(fresetStartD) && fendD.isSame(fresetEndD);
            let secisFullDay = sstartD.isSame(sresetStartD) && sendD.isSame(sresetEndD);
            if(firstisFullDay && !secisFullDay) {
                return -1;
            } else if(!firstisFullDay && secisFullDay) {
                return 1
            }

            return a.getStart().format('HH:mm:ss').localeCompare(b.getStart().format('HH:mm:ss'));
        });
        this.daysGrid.eventsGroup.selectAll('*').remove();

        //All events are now sorted in drawing order. We can now iterate over all
        //the sorted events and draw them accordingly
        for(let key in sortedEvents) {
            let currentEvent = events[key];
            let startD = currentEvent.getStart();
            let endD = currentEvent.getEnd();

            let resetStartD = moment(startD).startOf('day');
            let resetEndD = moment(endD).startOf('day');

            //Check if the event is a full day event. It means XX/YY/ZZZZ 00:00 to some other 00:00
            let isFullDay = startD.isSame(resetStartD) && endD.isSame(resetEndD);

            //Checking if the event is over multiple days or not
            //A multiple day event is an event that spans at least on 1ms on
            //another day
            let curDateString = resetStartD.format('YYYY-MM-DD');
            let todayCheckEnd = moment(endD).subtract(1, 'millisecond').format('YYYY-MM-DD');
            let isOneDay = curDateString === todayCheckEnd;

            let drawingSlot = this.getDrawingSlot(currentEvent, this.basePosMap, maxDrawnEventsPerCell);
            if(drawingSlot != -1) {
                if(isOneDay) {
                    let xPos = this.basePosMap[curDateString].x;
                    let yPos = this.basePosMap[curDateString].y + marginSpace + drawingSlot * eventSize + 2;
                    let bulletSize = eventSize / 3;
                    //If the day is not a full one we'll display a bullet
                    let drawProps = {
                        rectPosY : yPos + (isFullDay ? 2 : eventSize/ 3),
                        textPosX : xPos + 7 + (isFullDay ? 5 : bulletSize),
                        rx : isFullDay ? "5px" : "50%",
                        ry : isFullDay ? "5px" : "50%",
                        width : isFullDay? this.colSize - 4 : bulletSize,
                        height : isFullDay? eventSize - 4 : bulletSize,
                        textClass: 'events-marker-text' + (isFullDay ? '' : ' events-single')
                    }

                    //1 day only event but not full day, we don't render it as big as multidays one
                    let newRect = this.daysGrid.eventsGroup.append('rect')
                            .attr('x', xPos + 2)
                            .attr('y', drawProps.rectPosY)
                            .attr('rx', drawProps.rx)
                            .attr('ry', drawProps.ry)
                            .attr('width', drawProps.width)
                            .attr('height', drawProps.height)
                            .attr('stroke-width', '1')
                            .attr('stroke', '#ddd')
                            .attr('class', 'event-marker')
                            .attr('fill', currentEvent.getColor())
                            .attr('data-id',currentEvent.getId());
                    newRect.on('touchstart', this.onTouchEventStart.bind(this, currentEvent, newRect), { passive: true});
                    newRect.on('touchmove', this.onTouchEventMove.bind(this, currentEvent, newRect), { passive: true});
                    newRect.on('touchend', this.onTouchEventEnd.bind(this, currentEvent, newRect), { passive: true});
                    newRect.on('click', this.onMouseEventClick.bind(this, currentEvent, newRect));
                    newRect.on('dblclick',this.onDblClickEventHandler.bind(this, currentEvent));

                    var title = (isFullDay ? '' : currentEvent.getStart().format('HH:mm') + ' ') + currentEvent.getTitle();
                    if (title !== undefined && title.length > nbMaxChar) {
                        title = title.substring(0, nbMaxChar);
                    }

                    let newText = this.daysGrid.eventsGroup.append('text')
                            .attr('x', drawProps.textPosX)
                            .attr('y', yPos + eventSize/2 + eventSize /4 -2)
                            .attr('font-family', 'sans-serif')
                            .attr('font-size', Math.ceil(eventSize/2)+'px')
                            .attr('text-anchor', 'start')
                            .attr('class', drawProps.textClass)
                            .attr('data-id',currentEvent.getId());
                    this.sizeText(newText, title, this.colSize - (drawProps.textPosX - this.basePosMap[curDateString].x));

                    newText.on('touchstart', this.onTouchEventStart.bind(this, currentEvent, newText), { passive: true});
                    newText.on('touchmove', this.onTouchEventMove.bind(this, currentEvent, newText), { passive: true});
                    newText.on('touchend', this.onTouchEventEnd.bind(this, currentEvent, newText));
                    newText.on('click', this.onMouseEventClick.bind(this, currentEvent, newText));
                    newText.on('dblclick',this.onDblClickEventHandler.bind(this, currentEvent));
                } else {
                    let lastText, lastRec;
                    let counterDate: moment.Moment = moment(resetStartD);
                    while(counterDate.isBefore(endD)) {
                        let curDateString = counterDate.format('YYYY-MM-DD');
                        let xPos = this.basePosMap[curDateString].x;
                        let yPos = this.basePosMap[curDateString].y + marginSpace + drawingSlot * eventSize + 2;

                        let dayOfW = counterDate.isoWeekday()-1;
                        var title = (isFullDay ? '' : currentEvent.getStart().format('HH:mm') + ' ') + currentEvent.getTitle();
                        if (title !== undefined && title.length > nbMaxChar) {
                            title = title.substring(0, nbMaxChar);
                        }
                        if(lastRec === undefined || dayOfW === 0) {
                            //Creation of a new rectangle at current position
                            lastRec = this.daysGrid.eventsGroup.append('rect')
                            .attr('x', xPos + 3)
                            .attr('y', yPos + 3)
                            .attr('width', this.colSize -6)
                            .attr('height', eventSize -6)
                            .attr('rx', '5px')
                            .attr('ry', '5px')
                            .attr('stroke-width', '1')
                            .attr('stroke', '#999')
                            .attr('class', 'event-marker')
                            .attr('fill', currentEvent.getColor())
                            .attr('data-id',currentEvent.getId());
                            lastRec.on('touchstart', this.onTouchEventStart.bind(this, currentEvent, lastRec), { passive: true});
                            lastRec.on('touchmove', this.onTouchEventMove.bind(this, currentEvent, lastRec), { passive: true});
                            lastRec.on('touchend', this.onTouchEventEnd.bind(this, currentEvent, lastRec));
                            lastRec.on('click', this.onMouseEventClick.bind(this, currentEvent, lastRec));
                            lastRec.on('dblclick',this.onDblClickEventHandler.bind(this, currentEvent));

                            lastText = this.daysGrid.eventsGroup.append('text')
                                    .attr('x', xPos + 7)
                                    .attr('y', yPos + eventSize/2 + 4)
                                    .attr('font-family', 'sans-serif')
                                    .attr('font-size', Math.ceil(eventSize/2)+'px')
                                    .attr('text-anchor', 'start')
                                    .attr('class', 'events-marker-text')
                                    .attr('data-id',currentEvent.getId());
                            lastText.on('touchstart', this.onTouchEventStart.bind(this, currentEvent, lastText), { passive: true});
                            lastText.on('touchmove', this.onTouchEventMove.bind(this, currentEvent, lastText), { passive: true});
                            lastText.on('touchend', this.onTouchEventEnd.bind(this, currentEvent, lastText));
                            lastText.on('click', this.onMouseEventClick.bind(this, currentEvent, lastText));
                            lastText.on('dblclick',this.onDblClickEventHandler.bind(this, currentEvent));
                        } else {
                            lastRec.attr('width', parseFloat(lastRec.attr('width')) + this.colSize)
                        }
                        this.sizeText(lastText, title, parseFloat(lastRec.attr('width')) - 6 - 7);
                        counterDate.add(1, 'day');
                    }
                }
            } else {
                let counterDate: moment.Moment = moment(resetStartD);
                while(counterDate.isBefore(endD)) {
                    let curDateString = counterDate.format('YYYY-MM-DD');
                    let xPos = this.basePosMap[curDateString].x;
                    let length = this.daysMap[curDateString].events.length;
                    if (!this.daysMap[curDateString].extraText) {
                        this.daysMap[curDateString].extraText = this.daysGrid.eventsGroup.append('text')
                                .attr('x', this.config.dayNumberAlignment.indexOf('right') > -1 ? xPos + 5 : xPos + this.colSize - 5)
                                .attr('y', this.basePosMap[curDateString].y + this.rowSize - 13)
                                .attr('font-family', 'sans-serif')
                                .attr('font-size', '11px')
                                .attr('text-anchor', this.config.dayNumberAlignment.indexOf('right') > -1 ? 'start' : 'end')
                                .attr('class', 'extra-events-marker');
                                let fakeEvent = new SAIEvent('DUMMY',counterDate,counterDate,{});
                                this.daysMap[curDateString].extraText
                                .on('touchstart', this.onTouchEventStart.bind(this, fakeEvent), { passive: true})
                                .on('touchmove', this.onTouchEventMove.bind(this, fakeEvent), { passive: true})
                                .on('touchend', this.onTouchEventEnd.bind(this, fakeEvent));
                    }
                    let extraT = this.daysMap[curDateString].extraText;
                    extraT.text('+' + (length - this.daysMap[curDateString].usedSlots.length));
                    if(!isMobile.any) {
                        let me = this;
                        extraT.on('click', function() {
                            me.displayEventList(me.getClickedDate());
                        });
                    }

                    counterDate.add(1, 'day');
                }
            }

            if(isMobile.any) {
                // If we are on mobile, add a clickable zone on each days with event
                let eventDays:Array<moment.Moment> = this.getEventDays(resetStartD, endD);
                for(let day of eventDays) {
                    let strDay = day.format('YYYY-MM-DD');
                    if(this.fullDayTouchMap[strDay] === undefined || this.fullDayTouchMap[strDay] === null) {
                        let newRect = this.daysGrid.eventsGroup.append('rect')
                            .attr('x', this.basePosMap[strDay].x)
                            .attr('y', this.basePosMap[strDay].y)
                            .attr('width', this.colSize)
                            .attr('height', this.rowSize)
                            .attr('opacity', '0')
                            .attr('class','full-day-rect');
                        this.fullDayTouchMap[strDay] = newRect;
                        let fakeEvent = new SAIEvent('DUMMY',day,day,{});
                        newRect.on('touchstart', this.onTouchEventStart.bind(this, fakeEvent, newRect), { passive: true});
                        newRect.on('touchmove', this.onTouchEventMove.bind(this, fakeEvent, newRect), { passive: true});
                        newRect.on('touchend', this.onTouchEventEnd.bind(this, fakeEvent, newRect));
                    }
                }
            }
        }

        if(this.displayedEventListDate) {
            this.displayEventList(this.displayedEventListDate);
        }
    }

    public renderEvents(start: moment.Moment, end: moment.Moment): void {
        this.renderEventsList(this.calendarInterface.getManager().getEvents(start, end, SAIEventPosition.foreground));
    }

    private setupBehaviors() {
        let me = this;

        //Both mobile and desktop handle draging to switch between monthes
        let dragBehavior = d3.drag();
        dragBehavior.on('start', function () {
            me.__origin__ = parseInt(me.daysGrid.attr('posY'));
        })
        .on('drag', function () {
            if (d3.event.dy !== undefined) {
                var newY = me.__origin__ += parseInt(d3.event.dy);
                if(isNaN(newY)) {
                    newY = 0;
                }
                me.daysGrid.attr('transform', 'translate(' + 0 + ',' + newY + ')');
                me.daysGrid.attr('posY', newY);
            }
        })
        .on('end', function () {
            if (me.__origin__ > 60) {
                //We properly transition to the previous month and redraw
                var startPrevious = moment(me.currentDate).add(7, 'days').date(1).subtract(1, 'month');
                var rowDiff = Math.floor((startPrevious.diff(me.currentDate, 'days') + me.currentDate.isoWeekday()) / 7);
                me.daysGrid.transition()
                        .attr('transform', 'translate(0,' + (-rowDiff * me.rowSize + me.config.headerHeight) + ')')
                        .on('end', function () {
                            me.currentDate = startPrevious;
                            me.monthDrawGrid(me.daysOfWeek, me.colSize, me.rowSize);
                            me.monthRequestNewData();
                        }) ;
            } else if (me.__origin__ < -60) {
                //We properly transition to the next month and redraw
                var firstDayOfMonth = moment(me.currentDate).add(7, 'days').date(1);
                var lastDayOfMonth = moment(firstDayOfMonth).endOf('month');

                var numRemainingCells = 42 - (lastDayOfMonth.diff(firstDayOfMonth, 'days') + firstDayOfMonth.isoWeekday());
                var rowDif;
                if (numRemainingCells > 7) {
                    rowDif = 4;
                } else if (numRemainingCells > 0) {
                    rowDif = 5;
                } else {
                    rowDif = 6;
                }

                me.daysGrid.transition()
                        .attr('transform', 'translate(0,' + (-rowDif * me.rowSize + me.config.headerHeight) + ')')
                        .on('end', function () {
                            me.currentDate = firstDayOfMonth.add(1, 'month');
                            me.monthDrawGrid(me.daysOfWeek, me.colSize, me.rowSize);
                            me.monthRequestNewData();
                        });
            } else {
                me.daysGrid.transition()
                        .attr('transform', 'translate(0,'+me.config.headerHeight+')');
            }

            delete me.__origin__;
        });
        me.daysGrid.call(dragBehavior);


        if(!isMobile.any) {
            //Zoom through mousewheel is only allowed on desktop web as pinching
            //canno't be differentiated with current version of d3 without
            //duplicating events, as handlers consume events they manage on 
            //purpose to prevent multi-handling
            let zoomBehavior = d3.zoom();
            zoomBehavior.on('zoom', function() {
                //computing the day on which we zoomed
                let sourceEvent = d3.event.sourceEvent;
                if(sourceEvent !== undefined && sourceEvent != null) {
                    if(sourceEvent.targetTouches !== undefined && sourceEvent.targetTouches.length > 0) {
                        sourceEvent = sourceEvent.targetTouches[sourceEvent.targetTouches.length - 1];
                    }
                    let column = Math.floor(sourceEvent.pageX / me.colSize);
                    let row = Math.floor((sourceEvent.pageY - me.config.headerHeight) / me.rowSize);
                    let firstDayStart = moment(me.currentDate).date(1);
                    let firstDayMonth = firstDayStart.isoWeekday();
                    if(firstDayMonth !== 1) {
                        let nbDiffDays = firstDayMonth - 1;
                        firstDayStart = firstDayStart.subtract(nbDiffDays, 'days');
                    }

                    let zoomDate = firstDayStart.add(row*7 + column, 'days');
                    let zoomValue = d3.zoomTransform(this).k;
                    let eventArgs = [zoomValue >= me.__zoomValue__ ? 'in': 'out', zoomDate];
                    me.__zoomValue__ = zoomValue;
                    me.calendarInterface.triggerEvent('interfaceZoomed', eventArgs);
                }
            });
            me.daysGrid.call(zoomBehavior);
        }
    }

    private buildCalendarOptions() {
        if(this.config.showTopMenu) {
            this.buildTodayButton();
            let previousWeekButton = this.calendarOptionsDiv.append('div')
                .attr('class','week-change-button previous options-button');
            let previousWeekSvg = previousWeekButton.append('svg')
                .attr('width','30')
                .attr('height','30');

            previousWeekSvg.append('circle')
                .attr('cx','15')
                .attr('cy','15')
                .attr('r','14')
                .attr('style','stroke: none;')
                .attr('class','toggle-button-circle');

            previousWeekSvg.append('path')
                .attr('d','m 15.4,26.5 -9,-10.7 v -1.5 l 9,-10.8 3.1,3.3 -6.8,8.2 6.8,8.2 z')
                .attr('style','fill: #999; stroke: none;');
            previousWeekSvg.on('click', this.switchMonth.bind(this,-1));

            let nextWeekButton = this.calendarOptionsDiv.append('div')
                .attr('class','week-change-button next options-button');
            let nextWeekSvg = nextWeekButton.append('svg')
                .attr('width','30')
                .attr('height','30');

            nextWeekSvg.append('circle')
                .attr('cx','15')
                .attr('cy','15')
                .attr('r','14')
                .attr('style','stroke: none;')
                .attr('class','toggle-button-circle');

            nextWeekSvg.append('path')
                .attr('d','M13.5 26.5 L22.5 15.8 L22.5 14.3 L13.5 3.5 L10.4 6.8 L17.2 15 L10.4 23.2 Z')
                .attr('style','fill: #999; stroke: none;');

            nextWeekSvg.on('click', this.switchMonth.bind(this,1));

            this.buildCurrentMonth();
            if(this.config.showOptions) {
                this.buildParametersButton();
            }
            if(this.config.showModeSwitch) {
                this.buildDisplayTypeMenu();
            }
        }
    }

    private buildTodayButton() {
        let todayContainer = this.calendarOptionsDiv.append('div')
            .attr('class','options-button today-button');
        
        todayContainer.append('svg')
            .attr('width','40')
            .attr('height','40')
            .append('circle')
            .attr('cx','20')
            .attr('cy','20')
            .attr('r','20')
            .attr('style','stroke: none;')
            .attr('class','toggle-button-circle');

        todayContainer.append('img')
            .attr('src',require('./images/calendar-today.svg'))
            .attr('width', 30)
            .attr('height',30);

        todayContainer.on('click', this.moveToToday.bind(this));
    }

    private buildCurrentMonth() {
        if(this.config.showTopMenu) {
            if(this.currentMonthDisplay === undefined) {
                this.currentMonthDisplay = this.calendarOptionsDiv.append('div')
                    .attr('class','current-month');
            }
            if(this.currentDate !== undefined) {
                let dateDisplay = this.currentDate.format('MMMM YYYY');
                this.currentMonthDisplay.text(dateDisplay);
            }
        }
    }

    moveToToday() {
        if(!this.currentDate.isSame(moment().startOf('month'))) {
            this.currentDate = moment().startOf('month');
            this.monthDrawGrid(this.daysOfWeek, this.colSize, this.rowSize);
            this.monthRequestNewData();
        }
    }

    private buildParametersButton() {
        let optionsContainer = this.calendarOptionsDiv.append('div')
            .attr('class','options-button parameters-button');
        
        optionsContainer.append('svg')
            .attr('width','40')
            .attr('height','40')
            .append('circle')
            .attr('cx','20')
            .attr('cy','20')
            .attr('r','20')
            .attr('style','stroke: none;')
            .attr('class','toggle-button-circle');

        optionsContainer.append('img')
            .attr('src',require('./images/options.svg'))
            .attr('width', 30)
            .attr('height',30);
    }

    buildDisplayTypeMenu() {
        let displaySelection = this.calendarOptionsDiv.append('div')
            .attr('class','display-selection')
        
        displaySelection.append('div')
            .attr('class','display-selection-label')
            .text('Mois');
        
        let arrow = displaySelection.append('svg')
            .attr('width','30')
            .attr('height','30');

        arrow.append('path')
            .attr('d','m 3.5,14 10.7,9 h 1.5 L 26.5,14 23.2,10.9 15,17.7 6.8,10.9 Z')
            .attr('style','fill: #999; stroke: none;');
        
        displaySelection.on('click', this.toggleMenu.bind(this));

        let selectPos = $(displaySelection.node()).offset();
        selectPos.top = selectPos.top + 40

        let otherTypes = this.calendarOptionsDiv.append('div')
            .attr('class','display-selection-menu');
        
        for(let modeIdx in this.config.availableModes) {
            let mode = this.config.availableModes[modeIdx];
            otherTypes.append('div')
                .attr('class', 'item')
                .text(this.calendarInterface.getConfigLabel(mode))
                .attr('data-id',mode);
        }
        
        let fade = this.calendarOptionsDiv.append('div')
            .attr('class', 'fade');
        fade.on('click', this.toggleMenu.bind(this));

        this.calendarOptionsDiv.selectAll('.item').on('click', this.handleModeChange.bind(this));
    }

    toggleMenu() {
        let menu = $(this.calendarOptionsDiv.select('.display-selection-menu').node());
        let fade = $(this.calendarOptionsDiv.select('.fade').node());
        if(menu.is(':visible')) {
            menu.hide();
            fade.hide();
        } else {
            let menuItem = $(this.calendarOptionsDiv.select('.display-selection').node()); 
            let menupos = menuItem.offset();
            let parentPos = menuItem.offsetParent().offset();
            menu.css('left',menupos.left-parentPos.left);
            menu.show();
            fade.show();
        }
    }

    handleModeChange() {
        this.toggleMenu();
        let clickedItem = arguments[1];
        let nodeList = arguments[2];
        let selectedMode = $(nodeList[clickedItem]).data('id');
        if(selectedMode !== SAIEventInterfaceDisplayMode.MONTHES) {
            this.calendarInterface.resetInterface(this.currentDate,selectedMode);
        }
    }

    private switchMonth(direction) {
        var startPrevious = moment(this.currentDate).add(7, 'days').date(1).add(direction, 'month');
        this.currentDate = startPrevious;
        this.monthDrawGrid(this.daysOfWeek, this.colSize, this.rowSize);
        this.monthRequestNewData();
    }

    private getEventDays(start : moment.Moment, end: moment.Moment):Array<moment.Moment>  {
        let result=[];
        let currentDay = start.clone();
        result.push(currentDay.clone());
        currentDay = currentDay.add(1,'day');
        while(currentDay.isBefore(end)) {
            result.push(currentDay.clone());
            currentDay = currentDay.add(1,'day');
        }

        return result;
    }

    private clearFullDayTouchMap() {
        if(this.fullDayTouchMap !== undefined) {
            this.daysGrid.eventsGroup.selectAll('.full-day-rect').remove();
        }

        this.fullDayTouchMap = {};
    }
}

export { SAICalendarMonthRenderer };