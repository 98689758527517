(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("moment"), require("jquery"), require("d3"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define("sai-historyviewer", ["moment", "jquery", "d3", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["sai-historyviewer"] = factory(require("moment"), require("jquery"), require("d3"), require("lodash"));
	else
		root["sai-historyviewer"] = factory(root["moment"], root["jquery"], root["d3"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 