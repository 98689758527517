import { SAIEventInterface, SAIEventInterfaceDisplayMode, SAIEventInterfaceConfig } from "./sai-event-interface";
import { SAIEventManager, SAIEvent } from "./sai-event-core";
import * as moment from 'moment';
import { SAICalendarModeRenderer } from './sai-event-calendar-mode';
import { SAICalendarMonthRenderer } from './sai-event-calendar-mode-month';
import { SAICalendarYearsRenderer } from './sai-event-calendar-mode-years';
import { SAICalendarWeekRenderer } from "./sai-event-calendar-mode-week";

class SAICalendarConfig extends SAIEventInterfaceConfig {
    public headerFormat: string;
    public headerHeight: number;
    public dayNumberAlignment: string;
    public eventSize: number;
    public displayNewMarker: boolean;
    public headerAlignment: string;
    public showTopMenu: boolean;
    public showOptions: boolean;
    public showModeSwitch: boolean;

    constructor(options: any) {
        super(options);

        this.headerFormat = options.headerFormat || 'ddd';
        this.headerHeight = options.headerHeight || 30;
        this.dayNumberAlignment = options.dayNumberAlignment || 'top-right';
        this.eventSize = options.eventSize || 24;
        this.displayNewMarker = options.displayNewMarker || true;
        this.headerAlignment = options.headerAlignment || 'start';
        this.showTopMenu = options.showTopMenu !== undefined ? options.showTopMenu : true;
        this.showOptions = options.showOptions !== undefined ? options.showOptions : false;
        this.showModeSwitch = options.showModeSwitch !== undefined ? options.showModeSwitch : false;
    }
}

class SAIEventCalendar extends SAIEventInterface {
    protected config: SAICalendarConfig;
    private currentModeRenderer: SAICalendarModeRenderer;
    private static renderIteration: number = 0;
    protected modesNames;

    constructor(context: any) {
        super(context);
    }

    buildConfig(context: any): any {
        return new SAICalendarConfig(context);
    }

    public resetInterface(date?: moment.Moment, mode?:SAIEventInterfaceDisplayMode) {
        let resetDate = date || moment();
        let rendererConfig = {
            currentDate: resetDate,
            config: this.config,
            calendarInterface: this,
            rootElement: this.getRootElement(),
            instanceId: this.config.instanceId
        };

        if(mode !== undefined) {
            this.currentVisualMode = mode;
        }

        if(this.currentVisualMode === SAIEventInterfaceDisplayMode.MONTHES) {
            this.currentModeRenderer = new SAICalendarMonthRenderer(rendererConfig);
        } else if(this.currentVisualMode === SAIEventInterfaceDisplayMode.YEARS) {
            this.currentModeRenderer = new SAICalendarYearsRenderer(rendererConfig);
        } else if(this.currentVisualMode === SAIEventInterfaceDisplayMode.WEEKS) {
            this.currentModeRenderer = new SAICalendarWeekRenderer(rendererConfig);
        }

        super.resetInterface();
    }

    protected setupListeners() : void {
        this.setupZoomListeners();
    }

    private setupZoomListeners() : void {
        let me = this;
        let currentMainEl = $(me.getJQueryElement());
        let zoomTempHandling = function( event: JQuery.Event, direction: string, date : moment.Moment) {
            //Deletion of zoom handlers during 300ms to prevent multizoom events
            //to trigger interface changes
            currentMainEl.off('interfaceZoomed');
            setTimeout(function() {
                //Cleaning zoom handlers and setting up the zoom again
                currentMainEl.off('interfaceZoomed');
                currentMainEl.on('interfaceZoomed', zoomTempHandling);
            }, 300);

            //Computations of the current zoom mode
            let index = -1;
            let modes = me.getAllowedModes();
            for(let i = 0; i < modes.length; i++) {
                if(modes[i] === me.currentVisualMode) {
                    index = i;
                }
            }

            //base of zoom in/out, we might not be allowed to do so if we
            //reached max/min zoom
            if(direction == 'in') {
                //Taking next visual mode at given date
                if(index !== modes.length - 1) {
                    me.setMode(modes[index+1], date);
                }
            } else {
                //Taking previous visual mode at given date
                if(index > 0) {
                    me.setMode(modes[index-1], date);
                }
            }
        };

        //Deletion of potential handlers
        currentMainEl.off('interfaceZoomed');
        //Creation of a fresh handler for zooming
        currentMainEl.on('interfaceZoomed', zoomTempHandling);
    }

    public drawInterface() : void{
        this.currentModeRenderer.renderInterface();
    }

    public drawEventsList(events: Array<SAIEvent>) : void {
        this.currentModeRenderer.renderEventsList(events);
    }

    public renderEvents() : void {
        this.currentModeRenderer.renderEvents(this.start, this.end);
    }

    public drawCategories() : void {

    }

    public getAllowedModes() : Array<SAIEventInterfaceDisplayMode> {
        return [SAIEventInterfaceDisplayMode.YEARS, SAIEventInterfaceDisplayMode.MONTHES, SAIEventInterfaceDisplayMode.WEEKS];
    }

    public getNextRenderIteration() : number {
        SAIEventCalendar.renderIteration += 1;
        return SAIEventCalendar.renderIteration;
    }

    public getCurrentRenderIteration() : number {
        return SAIEventCalendar.renderIteration;
    }

    public getConfigLabel(mode:SAIEventInterfaceDisplayMode) {
        if(this.modesNames === undefined) {
            this.buildModeLabelList();
        }
        return this.modesNames[mode];
    }

    protected buildModeLabelList() {
        this.modesNames = {};
        this.modesNames[SAIEventInterfaceDisplayMode.WEEKS] = 'Semaine';
        this.modesNames[SAIEventInterfaceDisplayMode.MONTHES] = 'Mois';
        this.modesNames[SAIEventInterfaceDisplayMode.YEARS] = 'Année';
    }
}

export { SAIEventCalendar, SAICalendarModeRenderer, SAICalendarConfig }