import * as moment from "moment";
import { SAICalendarConfig } from './sai-event-calendar';
import { SAIEvent } from "./sai-event-core";

class SAICalendarModeRenderer {
    protected currentDate: moment.Moment;
    protected config: SAICalendarConfig;
    protected allEvents: Array<SAIEvent>;

    constructor(options: { currentDate: moment.Moment, config: SAICalendarConfig}) {
        this.currentDate = options.currentDate;
        this.config = options.config;
    }

    public renderInterface(): void { }

    public renderEventsList(events: Array<SAIEvent>): void { }

    public renderBackgroundEventsList(events: Array<SAIEvent>): void { }

    public renderEvents(start: moment.Moment, end: moment.Moment): void { }

    public setDate(date: moment.Moment) {
        this.currentDate = date;
    }
}

export { SAICalendarModeRenderer };