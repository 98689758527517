import { SAIEventCalendar, SAICalendarConfig } from './sai-event-calendar';
import { SAIEventInterfaceDisplayMode } from "./sai-event-interface";
import { SAICalendarModeRenderer } from './sai-event-calendar-mode';
import { SAIEvent,SAIEventPosition } from './sai-event-core';
import * as moment from 'moment';
import * as d3 from 'd3';
import { SAITimePeriod } from "./sai-event-main";
var isMobile = require('ismobilejs');
let $ = require('jquery');

class SAICalendarWeekRenderer extends SAICalendarModeRenderer {
    private daysOfWeek: Array<{ name: string, class: string}>;
    private daysMap: { [key: string] : {
            usedSlots : Array<{ num : number }>,
            events: Array<SAIEvent>,
            extraText: any
        }
    };
    private calendarInterface: SAIEventCalendar;

    private rootElement: any;
    private svgRoot: any;
    private daysGrid: any;
    private backgroundEvents: any;
    private foregroundEvents: any;
    private newEventMarker: any;
    private dropShadow: any;
    private daysHeaders: any;
    private calendarRoot: any;
    private eventList: any;
    private headerEvents: any;
    private overflowEvents: any;
    private toggleHeaderStateButton: any;
    private toggleState: string = 'closed';
    private overallMax: number;
    private svgToogleButton;
    private endDate: moment.Moment;
    private colSize: number;
    private rowSize: number;
    private usefulColSize: number;
    private eventFontSize: number;

    private __origin__ : any;
    private __zoomValue__ : number;

    private touchedEventStart: SAIEvent;
    private mousedEventDown: SAIEvent;
    private EVENT_HEIGHT: number;
    private LETTER_MEAN_EVENT_WIDTH: number;
    private LETTER_MEAN_25_WIDTH: number;
    private LETTER_HEIGHT: number = 14;
    private interfaceWidth : number;
    private interfaceHeight : number;
    private selectedEvent : any;
    private legendDialog : any;
    private startEventCreation: boolean;
    private newEventFromHeader: boolean;
    private newEventStartDate: moment.Moment;
    private newEventsDraw: {[day:string]: {start: moment.Moment, end: moment.Moment, element: any}};
    private newFullDayEventDraw: any;
    private hoursDisplay: JQuery<HTMLElement>;
    private mousePosition: {x: number, y:number};
    private copiedEvent: any;
    private instanceId: string;
    private calendarOptionsDiv: any;
    private currentWeekDisplay: any;
    private menuHeight: number;
    private startPosition : any;
    private lockX : boolean;
    private hoursLabels : any;

    constructor(options: { currentDate: moment.Moment, config: SAICalendarConfig, calendarInterface: SAIEventCalendar, rootElement: any}) {
        super(options);
        this.startEventCreation = false;
        this.newEventsDraw = {};
        this.legendDialog = options.config.legendDialog;
        let startOfWeek  = moment(options.currentDate).startOf('date');
        if (startOfWeek.isoWeekday() !== 1) {
            startOfWeek.subtract(startOfWeek.isoWeekday() - 1, 'days');
        }
        this.currentDate = startOfWeek;
        this.endDate = moment(this.currentDate).add(6, 'days').endOf('date');

        this.calendarInterface = options.calendarInterface;
        this.rootElement = options.rootElement;
        let namesDate = moment(this.currentDate);
        this.daysOfWeek = [{name: namesDate.isoWeekday(1).locale(this.config.locale).format(this.config.headerFormat), class: 'working-day'},
            {name: namesDate.isoWeekday(2).locale(this.config.locale).format(this.config.headerFormat), class: 'working-day'},
            {name: namesDate.isoWeekday(3).locale(this.config.locale).format(this.config.headerFormat), class: 'working-day'},
            {name: namesDate.isoWeekday(4).locale(this.config.locale).format(this.config.headerFormat), class: 'working-day'},
            {name: namesDate.isoWeekday(5).locale(this.config.locale).format(this.config.headerFormat), class: 'working-day'},
            {name: namesDate.isoWeekday(6).locale(this.config.locale).format(this.config.headerFormat), class: 'non-working-day'},
            {name: namesDate.isoWeekday(7).locale(this.config.locale).format(this.config.headerFormat), class: 'non-working-day'}
        ];
        this.instanceId = this.config.instanceId;
        this.currentDate.locale(this.config.locale);
        this.endDate.locale(this.config.locale);
    }

    public renderInterface() : void {
        let renderIteration = this.calendarInterface.getNextRenderIteration();
        this.interfaceWidth = this.calendarInterface.getWidth();
        let headerHeight = this.config.headerHeight;
        this.menuHeight = this.config.showTopMenu ? 40 : 0;
        let interfaceHeight = (this.calendarInterface.getHeight() - headerHeight - this.menuHeight) * 2;

        this.calendarRoot = this.rootElement.append('div')
            .attr('class', 'calendar calendar-week')
            .style('width', (this.interfaceWidth) + 'px');

        if(this.config.showTopMenu) {
            this.calendarOptionsDiv = this.calendarRoot.append('div')
                .attr('class','calendar-options');
        }

        this.buildCalendarOptions();

        let addEventDiv = this.calendarRoot.append('div')
            .attr('class','calendar-add-event')
            .style('top',(this.calendarInterface.getHeight() - 60)+'px');

        let svgContainer = this.calendarRoot.append('div')
            .attr('class','calendar-week calendar-grid-container')
            .style('height',this.calendarInterface.getHeight()+'px;');

        this.addUnselectEventHandler(svgContainer);

        this.colSize = (this.interfaceWidth - 68) / 7;
        this.usefulColSize = this.colSize - this.colSize*.08;
        this.rowSize = interfaceHeight / 24;

        this.svgRoot = svgContainer.append('svg')
            .attr('width', 3*(this.interfaceWidth)-18+'px')
            .attr('height', interfaceHeight+'px')
            .attr('class', 'calendar calendar-week')
            .style('transform','translate(-'+(this.colSize*7)+'px,0)')
            .attr('dx','-'+(this.colSize*7));

        $(this.svgRoot.node()).on('mousedown', this.handleCalendarMouseDown.bind(this, false));
        if(!document['activeInstances']) {
            document['activeInstances'] = {};
        }

        let instanceListeners = document['activeInstances'][this.instanceId];

        if(instanceListeners === undefined) {
            instanceListeners = {};
            document['activeInstances'][this.instanceId]  = instanceListeners;
        }

        for(let key in instanceListeners) {
            $(document).off(key, null, instanceListeners[key]);
        }
        instanceListeners['keydown'] = this.handleCtrlKeyDown.bind(this);
        instanceListeners['keyup'] = this.hideCurrentDate.bind(this);
        instanceListeners['mousemove'] = this.handleGlobalMousePosition.bind(this);
        for(let key in instanceListeners) {
            $(document).on(key, instanceListeners[key]);
        }

        this.eventFontSize = Math.min(this.rowSize / 4, 14);

        //We build a fake title to know the max length of a char. It'll be then
        //used to quickly compute maximum text size without having to compute them
        //live. Live computation is very expensive and breaks the normal flow
        //of the browser rendering
        let fakeComputeText = this.svgRoot.append('text')
            .attr('font-family', 'sans-serif')
            .attr('font-size', Math.ceil(this.eventFontSize)+'px')
            .attr('text-anchor', 'start')
            .attr('class', 'events-marker-text invisible-computation-text')
            .text('M');
        //We only take 80% of the letter width because the text is very unlikely
        //to contains only M letters
        this.LETTER_MEAN_EVENT_WIDTH = fakeComputeText.node().getBBox().width * 0.7;
        fakeComputeText.attr('font-size', 25);
        this.LETTER_MEAN_25_WIDTH = fakeComputeText.node().getBBox().width * 0.7;
        fakeComputeText.remove();

        this.drawHeader();

        this.daysGrid = this.svgRoot.append('g')
            .attr('class', 'calendar-grid');

        this.backgroundEvents = this.svgRoot.append('g')
            .attr('class', 'background-events');

        this.foregroundEvents = this.svgRoot.append('g')
            .attr('class', 'foreground-events')
            .style('transform','translate('+this.colSize*7+'px,0)');

        this.weekDrawGrid(this.daysOfWeek, this.colSize, this.rowSize);
        this.weekRequestNewData();
        
        let addEventSvg = addEventDiv.append('svg')
            .attr('width',60)
            .attr('height',60);

        this.dropShadow = addEventSvg.append('filter')
                .attr('xmlns', 'http://www.w3.org/2000/svg')
                .attr('id', 'dropshadow'+renderIteration)
                .attr('height', '130%');
        this.dropShadow.append('feGaussianBlur')
                .attr('in', 'SourceAlpha')
                .attr('stdDeviation', '3');
        this.dropShadow.append('feOffset')
                .attr('dx', '2')
                .attr('dy', '2')
                .attr('result', 'offsetblur');
        this.dropShadow.transfer = this.dropShadow.append('feComponentTransfer');
        this.dropShadow.transfer.append('feFuncA')
                .attr('type', 'linear')
                .attr('slope', '0.2');
        this.dropShadow.feMerge = this.dropShadow.append('feMerge');
        this.dropShadow.feMerge.append('feMergeNode');
        this.dropShadow.feMerge.append('feMergeNode')
                .attr('in', 'SourceGraphic');

        if(this.config.displayNewMarker){
            this.newEventMarker = addEventSvg.append('g')
                    .attr('class', 'calendar-month-add-group');
            this.newEventMarker.append('rect')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 60)
                    .attr('height', 40)
                    .attr('transform', 'skewY(15)')
                    .attr('class', 'calendar-add-event')
                    .attr('filter', 'url(#dropshadow'+renderIteration+')');
            this.newEventMarker.append('path')
                    .attr('class', 'calendar-add-event-plus')
                    .attr('d', 'M' + 27 + ' ' + 17 + ' h7 v7 h7 v7 h-7 v7 h-7 v-7 h-7 v-7 h7 z');
            this.newEventMarker.on('click', this.weekOnNewMarkerClick.bind(this));
        }

        this.setupBehaviors();
        $('.calendar-grid-container:visible').scrollTop(this.rowSize*7);
    }

    weekOnNewMarkerClick(atDate? : moment.Moment) {
        this.calendarInterface.triggerEvent('newEvent', [atDate || moment()]);
    }

    weekRequestNewData() {
        let startDate = this.currentDate;

        if (startDate.isoWeekday() !== 1) {
            startDate.subtract(startDate.isoWeekday() - 1, 'days');
        }
        let endDate = moment(startDate).add(7, 'days');

        this.calendarInterface.setStart(startDate);
        this.calendarInterface.setEnd(endDate);

        let fetchPeriod : SAITimePeriod = new SAITimePeriod(startDate, endDate);
        this.calendarInterface.getManager().changePeriod(fetchPeriod);
    }

    weekDrawGrid(daysOfWeek, colSize, rowSize) {
        let me = this;

        this.daysGrid.lines = this.daysGrid.append('g')
            .attr('class', 'calendar-lines');
        
        me.hoursLabels = this.daysGrid.append('g')
            .attr('class','calendar-hours-label')
            .style('transform','translate('+this.colSize*7+'px,0)');

        me.hoursLabels.append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 50)
                .attr('height', 24 * rowSize)
                .style('fill','#FFFF');

        for (var vertId = 0; vertId < 24; vertId++) {
            var x = (vertId) * colSize + 50;
            this.daysGrid.lines.append('line')
                    .attr('x1', x)
                    .attr('y1', 0)
                    .attr('x2', x)
                    .attr('y2', 24 * rowSize)
                    .attr('class', 'calendar-line');
        }

        let horId = 0;
        for (horId = 0; horId < 24; horId++) {
            var y = horId * rowSize;
            this.daysGrid.lines.append('line')
                .attr('x1', 0)
                .attr('y1', y)
                .attr('x2', 3*(this.interfaceWidth)-18)
                .attr('y2', y)
                .attr('class', 'calendar-line');
            
            me.hoursLabels.append('text')
                .attr('x', 40)
                .attr('y', y+14)
                .text(horId+'h00')
                .attr('text-anchor', me.config.dayNumberAlignment.indexOf('right') > -1? 'end' : 'start')
                .attr('class', 'calendar-hour');
        }
        this.daysGrid.lines.append('line')
            .attr('x1', 0)
            .attr('y1', horId * rowSize)
            .attr('x2', 3*(this.interfaceWidth)-18)
            .attr('y2', horId * rowSize)
            .attr('class', 'calendar-line');

        // draw the half-hour lines
        for (horId = 0; horId < 24; horId++) {
            var y = horId * rowSize + rowSize/2;
            this.daysGrid.lines.append('line')
                .attr('x1', 50)
                .attr('y1', y)
                .attr('x2', 3*(this.interfaceWidth)-18)
                .attr('y2', y)
                .attr('class', 'calendar-line-half');
        }
    }

    private onTouchEventStart(saiEvent: SAIEvent) {
        this.touchedEventStart = saiEvent;
    }

    private onTouchEventMove(saiEvent: SAIEvent) {
        this.touchedEventStart = undefined;
    }

    private onTouchEventEnd(saiEvent: SAIEvent) {
        if(this.touchedEventStart === saiEvent) {
            d3.event.stopPropagation();
            d3.event.preventDefault();
        }

        this.touchedEventStart = undefined;
    }

    private onMouseEventClick(event: SAIEvent) :void {
        d3.event.stopPropagation();
        d3.event.preventDefault();
    }

    private sizeText(node, title, maxSize, baseLetter?: number){
        baseLetter = baseLetter || this.LETTER_MEAN_EVENT_WIDTH;
        //Adding 2 to the normal computation as we know that we're using at least
        //one ':', a ' ' and 4 numbers which are small chars compared to an 'M'
        node.text(title.substring(0, Math.ceil(maxSize/this.LETTER_MEAN_EVENT_WIDTH) + 2));
    }

    private sortByDate(a:SAIEvent,b:SAIEvent) {
        //Comparing by date
        let baseCompare = a.getStart().format('YYYY-MM-DD').localeCompare(b.getStart().format('YYYY-MM-DD'));
        if(baseCompare != 0) {
            return baseCompare;
        }

        let fstartD = a.getStart();
        let fendD = a.getEnd();
        let fresetStartD = moment(fstartD).startOf('day');
        let fresetEndD = moment(fendD).startOf('day');
        let ftodayCheckEnd = moment(fendD).subtract(1, 'millisecond').format('YYYY-MM-DD');
        let firstSpan = !fresetStartD.isSame(ftodayCheckEnd);

        let sstartD = b.getStart();
        let sendD = b.getEnd();
        let sresetStartD = moment(sstartD).startOf('day');
        let sresetEndD = moment(sendD).startOf('day');
        let stodayCheckEnd = moment(sendD).subtract(1, 'millisecond').format('YYYY-MM-DD');
        let secondSpan = !sresetStartD.isSame(stodayCheckEnd);

        if(firstSpan && !secondSpan) {
            return -1;
        } else if(!firstSpan && secondSpan) {
            return 1;
        }

        let firstisFullDay = fstartD.isSame(fresetStartD) && fendD.isSame(fresetEndD);
        let secisFullDay = sstartD.isSame(sresetStartD) && sendD.isSame(sresetEndD);
        if(firstisFullDay && !secisFullDay) {
            return -1;
        } else if(!firstisFullDay && secisFullDay) {
            return 1
        }

        return a.getStart().format('HH:mm:ss').localeCompare(b.getStart().format('HH:mm:ss'));
    }

    public renderEventsList (events: Array<SAIEvent>) {
        this.drawCurrentDayBackground();
        super.renderEventsList(events);
        
        this.updateHeaderLabel();
        // clear the current events
        this.daysHeaders.selectAll('.calendar-fullday-event').remove();
        this.calendarRoot.selectAll('.calendar-day-event').remove();

        this.daysMap = {};

        var eventSize = this.config.eventSize;
        var marginSpace = 30;
        var meanCharSize = 4;

        let sortedEvents = events.sort(this.sortByDate);

        //All events are now sorted in drawing order. We can now iterate over all
        //the sorted events and draw them accordingly
        let simpleEvents = new Map();
        this.overallMax = 0;
        let maxEvents = sortedEvents.length;
        let fullDayEvents = this.initialiseFulldayEventsMap(maxEvents);
        if(this.headerEvents.select('.overflow-events').empty()) {
            this.overflowEvents = this.headerEvents.append('g')
                .attr('class','overflow-events')
        }

        if(this.legendDialog) {
            this.legendDialog.emptyContent();
        }
        let legendItems = [];
        let hasRenderedEvents = false;

        for(let key in sortedEvents) {
            let currentEvent = events[key];
            let startD = moment(currentEvent.getStart());
            let endD = moment(currentEvent.getEnd());

            if(endD < this.currentDate || startD > this.endDate) {
                continue;
            }

            hasRenderedEvents = true;

            if(this.legendDialog && (legendItems[currentEvent.getColorLegend()] === undefined || legendItems[currentEvent.getColorLegend()].indexOf(currentEvent.getColor()) < 0)) {
                legendItems[currentEvent.getColorLegend()] = [currentEvent.getColor()];
                this.legendDialog.addContent($('<div class="legend-item">'+
                '<span class="legend-item-color" style="background-color: '+currentEvent.getColor()+'"></span>'+
                '<span class="legend-item-title">'+currentEvent.getColorLegend()+'</span></div>'));
            }

            let currentEventSvg;

            let beginingOfEndDate = endD.clone();
            if(endD.diff(startD,'days') >= 1 || (startD.date() !== endD.date() && endD.isAfter(beginingOfEndDate.startOf('day')))) {
                // multiple days
                let displayStart = moment.max(this.currentDate, startD).startOf('date');
                let displayEnd = moment.min(this.endDate, endD);
                let dayCounter = moment(displayStart);
                let maxPosition = 0;
                let availableSlots = [];
                let eventLength = 0;
                while(dayCounter < displayEnd) {
                    let key: string = moment(dayCounter).format('YYYYMMDD');
                    let currentDaySlots = fullDayEvents.get(key);
                    // Retrieve the available slots for the current day
                    availableSlots.push(this.getAvailableSlots(currentDaySlots));
                    eventLength++;
                    dayCounter.add(1,'days');
                }

                let overallAvailableSlot = availableSlots[0];
                // Intersect all the available slots
                if(availableSlots.length > 1) {
                    for(let i=1; i<availableSlots.length; i++) {
                        overallAvailableSlot = overallAvailableSlot.filter(value => -1 !== availableSlots[i].indexOf(value));
                    }
                }

                maxPosition = overallAvailableSlot[0];
                this.overallMax = Math.max(this.overallMax, maxPosition);

                // Update the slots with the selected slot
                dayCounter = moment(displayStart);
                while(dayCounter < displayEnd) {
                    let key: string = moment(dayCounter).format('YYYYMMDD');
                    let currentDaySlots = fullDayEvents.get(key);
                    currentDaySlots[maxPosition] = currentEvent;
                    dayCounter.add(1,'days');
                }

                let xpos = displayStart.diff(this.currentDate,'days') * this.colSize + 50;
                let ypos = maxPosition * 20;
                let width = (eventLength - 1) * this.colSize + this.usefulColSize;
                if(maxPosition < 2) {
                    currentEventSvg = this.headerEvents.append('g')
                    .attr('height','20')
                    .attr('width',width)
                    .attr('transform','translate('+xpos+','+ypos+')')
                    .attr('class', 'calendar-fullday-event');
                } else {
                    currentEventSvg = this.overflowEvents.append('g')
                    .attr('height','20')
                    .attr('width',width)
                    .attr('transform','translate('+xpos+','+ypos+')')
                    .attr('class', 'calendar-fullday-event');
                }
                currentEventSvg.append('title')
                    .text(this.getEventPopupTitle(currentEvent, 'FULL'));
                
                currentEventSvg.append('rect')
                    .attr('x','1')
                    .attr('y','1')
                    .attr('rx','2')
                    .attr('ry','2')
                    .attr('width',width-2)
                    .attr('height','18')
                    .attr('style','fill:'+currentEvent.getColor()+';stroke:white;');

                if(currentEvent.getStart() < this.currentDate || currentEvent.getEnd() > this.endDate) {
                    let maskId = Math.floor((Math.random() * 100000) + 1);
                    let mask = currentEventSvg.append('mask')
                        .attr('id','mask'+maskId);

                    mask.append('rect')
                        .attr('x',1)
                        .attr('y',1)
                        .attr('width',width-2)
                        .attr('height',18)
                        .attr('style','stroke:none; fill: #ffffffff');
                    
                    if(currentEvent.getStart() < this.currentDate) {
                        mask.append('path')
                            .attr('d','M0 0 L10 0 L0 10 L10 20 L0 20 Z')
                            .attr('style','stroke:none; fill: #000000ff');
                    }

                    if(currentEvent.getEnd() > this.endDate) {
                        mask.append('path')
                            .attr('d','M'+(width)+' 0 L'+(width-12)+' 0 L'+(width)+' 10 L'+(width-12)+' 20 L'+(width)+' 20 Z')
                            .attr('style','stroke:none; fill: #000000ff');
                    }

                    currentEventSvg.select('rect').attr('mask','url(#mask'+maskId+')');
                }
                let clipId = Math.floor((Math.random() * 100000) + 1);
                currentEventSvg.append('clipPath')
                    .attr('id','clip-'+clipId)
                    .append('path')
                    .attr('d','M10 2 L'+(width-10)+' 2 L'+(width-10)+' 18 L10 18 Z');

                let eventText = '';
                if(currentEvent.getStart().hour() !== 0 || currentEvent.getStart().minute() !== 0) {
                    eventText = currentEvent.getStart().format('HH:mm') + ', ';
                }
                eventText += currentEvent.getTitle();
                currentEventSvg.append('text')
                    .attr('x','10')
                    .attr('y','15')
                    .text(eventText)
                    .attr('font-family', 'sans-serif')
                    .attr('font-size', '15px')
                    .attr('clip-path','url(#clip-'+clipId+')')
                    .attr('fill',this.getTextColor(currentEvent.getColor()));
            } else {
                //single day
                currentEventSvg = this.drawEvent(currentEvent, simpleEvents, this.usefulColSize-5, this.foregroundEvents, 'foreground');
            }
            this.addClickEventHandler(currentEventSvg,currentEvent);
        }

        if(this.legendDialog) {
            if(hasRenderedEvents) {
                let me = this;
                this.legendDialog.getContent().children().sort((a,b) => {
                    let firstLabel = $(a).find('.legend-item-title').text();
                    let secondLabel = $(b).find('.legend-item-title').text();
                    return firstLabel.localeCompare(secondLabel, me.config.locale.toLowerCase());
                }).detach().appendTo(this.legendDialog.getContent());
                this.legendDialog.show();
                this.calendarInterface.triggerEvent('legendUpdate', [this.legendDialog]);
            } else {
                this.legendDialog.hide();
            }
        }

        let headerHeight = this.config.headerHeight + (this.overallMax + 1) * 20;
        this.daysHeaders.attr('style','height: '+headerHeight+'px;');
        this.headerEvents.attr('height',(this.overallMax + 1) * 20);
        $('.calendar-grid-container:visible').css('top',headerHeight + this.menuHeight +'px');
        $('.calendar-grid-container:visible').css('height',(this.calendarInterface.getHeight()-headerHeight-this.menuHeight)+'px');
        
        if(this.overallMax > 2) {
            this.drawClosedHeader(fullDayEvents);
        } else {
            this.headerEvents.select('.extra-day-events').style('visibility', 'hidden');
            this.headerEvents.select('.overflow-events').style('visibility', 'visible');
            $(this.toggleHeaderStateButton.node()).hide();
        }

        let me = this;
        simpleEvents.forEach(function(value,key) {
            me.adaptEventsPosition(value, false);
        });

        this.drawCurrentTimeLine();
        $('.calendar-grid-container:visible').scrollTop(this.rowSize*7);
    }

    public renderBackgroundEventsList(events:Array<SAIEvent>) {
        let sortedEvents = events.sort(this.sortByDate);
        let hasRenderedEvents = false;
        let simpleEvents = new Map();
        let legendItems = [];

        for(let key in sortedEvents) {
            let currentEvent = events[key];
            let startD = moment(currentEvent.getStart());
            let endD = moment(currentEvent.getEnd());

            if(endD < this.currentDate || startD > this.endDate) {
                continue;
            }
            
            if(this.legendDialog && (legendItems[currentEvent.getColorLegend()] === undefined || legendItems[currentEvent.getColorLegend()].indexOf(currentEvent.getColor()) < 0)) {
                // First background event
                if(!hasRenderedEvents && this.legendDialog.getContent().children().length > 0) {
                    this.legendDialog.addContent($('<div class="background-events-splitter"></div>'));
                }
                legendItems[currentEvent.getColorLegend()] = [currentEvent.getColor()];
                this.legendDialog.addContent($('<div class="legend-item background">'+
                '<span class="legend-item-color" style="background-color: '+currentEvent.getColor()+'"></span>'+
                '<span class="legend-item-title">'+currentEvent.getColorLegend()+'</span></div>'));
            }

            hasRenderedEvents = true;

            if(endD.diff(startD,'days') > 1 || startD.date() !== endD.date()) {
                // multiple days, events have to be splitted by day
                let currentStart = moment.max(moment(this.currentDate).startOf('day'), startD);
                let currentEnd = moment.min(endD, moment(currentStart).endOf('day'));
                while(!currentEnd.isAfter(endD) && !currentEnd.isAfter(this.endDate) && currentStart.isBefore(currentEnd)) {
                    let subEvent = currentEvent.clone();
                    subEvent.setStart(currentStart);
                    subEvent.setEnd(currentEnd);
                    this.drawEvent(subEvent, simpleEvents, this.colSize, this.backgroundEvents, 'background');

                    currentStart = moment(currentStart).add(1, 'day').startOf('day');
                    currentEnd = moment.min(endD, moment(currentStart).endOf('day'));
                }
            } else {
                this.drawEvent(currentEvent, simpleEvents, this.colSize, this.backgroundEvents, 'background');
            }
        }

        let me = this;
        simpleEvents.forEach(function(value,key) {
            me.adaptEventsPosition(value, true);
        });

        if(this.legendDialog) {
            if(hasRenderedEvents) {
                let me = this;
                this.legendDialog.getContent().children('.background').sort((a,b) => {
                    let firstLabel = $(a).find('.legend-item-title').text();
                    let secondLabel = $(b).find('.legend-item-title').text();
                    return firstLabel.localeCompare(secondLabel, me.config.locale.toLowerCase());
                }).detach().appendTo(this.legendDialog.getContent());
                this.legendDialog.show();
                this.calendarInterface.triggerEvent('legendUpdate', [this.legendDialog]);
            }
        }
    }

    private drawEvent(event:SAIEvent, eventsMap:any, width: number, container:any, cssClass:string) {
        let key: string = moment(event.getStart()).format('YYYYMMDD');
        let currentDayEvents = eventsMap.get(key);
        // No slots for the current day, so we create them
        if(currentDayEvents === undefined) {
            currentDayEvents = [];
            eventsMap.set(key, currentDayEvents);
        }

        let currentDay = moment(event.getStart()).startOf('date');
        let posX = currentDay.diff(this.currentDate,'days') * this.colSize + 50;
        let posY = this.rowSize * (event.getStart().diff(currentDay,'hours',true));

        let currentEventSvg = container.append('g')
            .attr('class','calendar-day-event ' + cssClass)
            .attr('transform','translate('+posX+','+posY+')')
            .attr('rawTitle',event.getTitle());
        
        currentDayEvents.push(currentEventSvg);

        currentEventSvg.append('rect')
            .attr('x','0')
            .attr('y','0')
            .attr('rx','2')
            .attr('ry','2')
            .attr('width', width)
            .attr('height',this.rowSize * event.getEnd().diff(event.getStart(),'hours',true))
            .attr('style','fill:'+event.getColor()+';stroke:white;');

        let availableWidth = width;
        let availableHeight = this.rowSize * event.getEnd().diff(event.getStart(),'hours',true)-2;

        currentEventSvg.append('title')
            .text(this.getEventPopupTitle(event, (availableHeight > this.eventFontSize+5) ? 'TEXT_ONLY' : 'HOURS'));

        if(availableHeight > this.eventFontSize+5) {
            let clipId = Math.floor((Math.random() * 100000) + 1);
            currentEventSvg.append('clipPath')
                .attr('id','clip-'+clipId)
                .append('rect')
                .attr('x','2')
                .attr('y','2')
                .attr('width',availableWidth)
                .attr('height',availableHeight);

            currentEventSvg.append('path')
                .attr('id', 'path-hour-'+clipId)
                .attr('class', 'hour-path')
                .attr('fill', 'none')
                .attr('stroke','none')
                .attr('d',this.generateTextPath(availableWidth,15,this.eventFontSize,5,2));
            
            let textColor = this.getTextColor(event.getColor());

            currentEventSvg.append('text')
                .attr('fill',textColor)
                .attr('font-family', 'sans-serif')
                .attr('font-size', Math.ceil(this.eventFontSize)+'px')
                .append('textPath')
                .attr('href','#path-hour-'+clipId)
                .text(event.getStart().format('HH:mm')+ ' - ' + event.getEnd().format('HH:mm'));

            this.generateTitleText(currentEventSvg,event.getTitle(),textColor,availableWidth,availableHeight,5,2*this.eventFontSize + 2,5);
        }
        return currentEventSvg;
    }

    public renderEvents(start: moment.Moment, end: moment.Moment): void {
        this.renderEventsList(this.calendarInterface.getManager().getEvents(start, end, SAIEventPosition.foreground));
        this.renderBackgroundEventsList(this.calendarInterface.getManager().getEvents(start, end, SAIEventPosition.background));
    }

    private setupBehaviors() {
        let me = this;

        //Both mobile and desktop handle draging to switch between monthes
        let dragBehavior = d3.drag();
        dragBehavior.on('start', function () {
            me.startPosition = parseInt(me.svgRoot.attr('dx'));
            me.lockX = undefined;
        })
        .on('drag', function () {
            if(me.startEventCreation) {
                me.handleCalendarMouseMove({x: d3.event.sourceEvent.x, y:d3.event.sourceEvent.y});
            } else {
                if(me.lockX === undefined) {
                    // Check if we mainly move along the X or Y axis and lock
                    if (d3.event.dx !== undefined && d3.event.dy !== undefined) {
                        let dx = Math.abs(parseInt(d3.event.dx));
                        let dy= Math.abs(parseInt(d3.event.dy));
                        if(dy > dx) {
                            me.lockX = true;
                        } else {
                            me.lockX = false;
                        }
                    }
                }
                if (d3.event.dx !== undefined && d3.event.dy !== undefined && me.lockX === false) {
                    // Scroll along X
                    let dx = parseInt(d3.event.dx);
                    let currentPosition = parseInt(me.svgRoot.attr('dx'));
                    var newX = currentPosition + dx;
                    if(isNaN(newX)) {
                        newX = currentPosition;
                    }


                    if(newX > 0) {
                        newX = 0;
                    } else if(newX < -(me.colSize * 14)) {
                        newX = -(me.colSize * 14);
                    }

                    me.svgRoot.style('transform', 'translate(' + newX + 'px,0)');
                    me.svgRoot.attr('dx', newX);
                } else if (d3.event.dx !== undefined && d3.event.dy !== undefined && me.lockX === true){
                    // Scroll along Y
                    let dy = parseInt(d3.event.dy);
                    let currentScroll = $('.calendar-grid-container:visible').scrollTop();
                    let scrollValue : number = currentScroll - dy >= 0 ? currentScroll - dy : 0;
                    $('.calendar-grid-container:visible').scrollTop(scrollValue);
                }
            }
        })
        .on('end', function () {
            if(me.startEventCreation) {
                me.handleCalendarMouseUp({x: d3.event.sourceEvent.x, y:d3.event.sourceEvent.y}, d3.event.sourceEvent.srcElement);
            } else {
                let currentPosition = parseInt(me.svgRoot.attr('dx'));
            
                if (me.startPosition - currentPosition < -200) {
                    //We properly transition to the previous week and redraw
                    var startPrevious = moment(me.currentDate).add(-7, 'days');
    
                    me.svgRoot.transition()
                            .style('transform', 'translate(0,0)')
                            .on('end', function () {
                                me.currentDate = startPrevious;
                                me.moveToDate();
                            });
                } else if (me.startPosition - currentPosition > 200) {
                    //We properly transition to the next week and redraw
                    var startPrevious = moment(me.currentDate).add(7, 'days');
    
                    me.svgRoot.transition()
                        .style('transform', 'translate(-'+14*me.colSize+'px,0)')
                        .on('end', function () {
                            me.currentDate = startPrevious;
                            me.moveToDate();
                        });
                } else {
                    // Go back to the middle position
                    me.svgRoot.transition()
                        .style('transform', 'translate(-'+(7*me.colSize)+'px,0)');
                    me.svgRoot.attr('dx',-(7*me.colSize));
                }
            }
        });
        me.svgRoot.call(dragBehavior);
    }

    private drawHeader() {
        this.daysHeaders = this.calendarRoot.append('div')
            .attr('class', 'calendar-week calendar-header')
            .style('height', this.config.headerHeight+'px;');
        
        this.headerEvents = this.daysHeaders.append('svg').attr('width','100%').attr('class','calendar calendarHeader');
        $(this.headerEvents.node()).on('mousedown',this.handleCalendarMouseDown.bind(this, true));

        this.addUnselectEventHandler(this.headerEvents, true);
        let counter: number = 0;
        for(let dayIndex in this.daysOfWeek) {
            this.headerEvents.append('line')
                .attr('x1',(50 + counter*this.colSize))
                .attr('y1','0')
                .attr('x2',(50 + counter*this.colSize))
                .attr('y2','100%')
                .attr('class', 'calendar-line');

            counter++;
        }
        // add the last line
        this.headerEvents.append('line')
            .attr('x1',(50 + counter*this.colSize))
            .attr('y1','0')
            .attr('x2',(50 + counter*this.colSize))
            .attr('y2','100%')
            .attr('class', 'calendar-line');

        this.updateHeaderLabel();

        this.toggleHeaderStateButton = this.daysHeaders.append('div')
            .attr('class','show-all-event-icon');
        this.svgToogleButton = this.toggleHeaderStateButton.append('svg')
            .attr('width','50')
            .attr('height','50');

        this.svgToogleButton.append('circle')
            .attr('cx','25')
            .attr('cy','25')
            .attr('r','24')
            .attr('style','stroke: none;')
            .attr('class','toggle-button-circle');

        this.svgToogleButton.append('path')
            .attr('d','M10 28.8 L14 32.8 L25 23.8 L36 32.8 L40 28.8 L26 16.8 L24 16.8 Z')
            .attr('style','fill: #999; stroke: none; display: none;')
            .attr('id','arrow-up');
        this.svgToogleButton.append('path')
            .attr('d','m 10,20.8 4,-4 11,9 11,-9 4,4 -14,12 h -2 z')
            .attr('style','fill: #999; stroke: none;')
            .attr('id','arrow-down');
        this.toggleHeaderStateButton.on('click',this.toggleHeaderState.bind(this));
    }

    updateHeaderLabel() {
        this.daysHeaders.selectAll('.calendar-day').remove();
        let counter: number = 0;
        let now = moment();
        for(let dayIndex in this.daysOfWeek) {
            let date = moment(this.currentDate)
            date.add(counter, 'days');
            date.locale(this.config.locale);
            if(date.isSame(now.startOf('day'))) {
                this.daysHeaders.append('div')
                .attr('class','calendar-day today')
                .attr('style','left: '+ (50 + counter*this.colSize)+'px;')
                .text(date.format('ddd DD MMM'));
            } else {
                this.daysHeaders.append('div')
                .attr('class','calendar-day')
                .attr('style','left: '+ (50 + counter*this.colSize)+'px;')
                .text(date.format('ddd DD MMM'));
            }

            counter++;
        }
    }

    buildDaySlots(maxSlot: number) : any {
        return Array.apply(null, Array(maxSlot)).map(() => {});
    }

    getAvailableSlots(daySlots: any) {
        let indexes = [];
        for(let i=0; i< daySlots.length; i++) {
            if(daySlots[i] === undefined) {
                indexes.push(i)
            }
        }
        return indexes;
    }

    getTextColor(hex) {
        if(hex.match(/^#[a-fA-F\d]{3}$/i)) {
            hex = '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
        }
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        let r = parseInt(result[1], 16);
        let g = parseInt(result[2], 16);
        let b = parseInt(result[3], 16);

        r /= 255, g /= 255, b /= 255;
        let brightness = ((r * 299) + (g * 587) + ( b* 114)) / 1000;
        return brightness > 0.5 ? '#000' : '#fff';
    }

    adaptEventsPosition(events, fullWidth:boolean) {
        let lastEventEnding = undefined;
        let columns = [];
        let block_width = fullWidth ? this.colSize : this.usefulColSize;
        let me = this;
        (events).forEach(function(e, index) {
            let boundingBox = me.getPosition(e);
            if (lastEventEnding !== null && boundingBox.top >= lastEventEnding) {
                me.packEvents( columns, block_width );
                columns = [];
                lastEventEnding = undefined;
            }
    
            var placed = false;
            for (var i = 0; i < columns.length; i++) {                   
                var col = columns[ i ];
                if (!me.collidesWith( col[col.length-1].boundingBox, boundingBox ) ) {
                    col.push({boundingBox : boundingBox, event: e});
                    placed = true;
                    break;
                }
            }
    
            if (!placed) {
                columns.push([{boundingBox : boundingBox, event: e}]);
            }
    
            if (lastEventEnding === undefined || boundingBox.bottom > lastEventEnding) {
            lastEventEnding = boundingBox.bottom;
            }
        });
    
        if (columns.length > 0) {
            this.packEvents( columns, block_width );
        }
    }
    
    packEvents( columns, block_width )
    {
        var n = columns.length;
        for (var i = 0; i < n; i++) {
            var col = columns[ i ];
            for (var j = 0; j < col.length; j++)
            {
                var bubble = col[j].event;
                let transfo = this.getTranslation(bubble);
                let newPosX = transfo.x + (block_width * i / n);
                let newWidth = block_width/n - 1;
                bubble.attr('transform','translate('+ newPosX + ','+ transfo.y +')');
                bubble.select('rect').attr('width',newWidth);
                let rectH = bubble.select('rect').attr('height');
                if(!bubble.select('text').empty()) {
                    this.updateElementTextPaths(bubble, newWidth - 5, rectH, this.eventFontSize, 5, 2);
                    let color = bubble.select('text').attr('fill');
                    this.generateTitleText(bubble,bubble.attr('rawTitle'),color,newWidth,rectH,5,2*this.eventFontSize + 2,5);
                }
            }
        }
    }
    
    collidesWith( a, b )
    {
        let tolerance = 1;
        return (a.bottom - tolerance) > (b.top + tolerance) && (a.top + tolerance) < (b.bottom - tolerance);
    }

    getPosition(element) {
        let transformation =  this.getTranslation(element);
        let bottom = transformation.y + parseFloat(element.select('rect').attr('height'));
        return {
            top: transformation.y,
            bottom: bottom
        }
    }

    getTranslation(element) {
        let transformation = element.attr('transform');
        let result = /(\d+\.*\d*)[\s,](\d+\.*\d*)/i.exec(transformation);
        return {x: parseFloat(result[1]), y: parseFloat(result[2])}
    }

    generateTextPath(width: number, height: number, lineHeight: number, xOffset: number, yOffset: number) {
        let path = '';
        let currentRow = lineHeight + yOffset;
        while(currentRow < height) {
            path += 'M'+xOffset+' ' + currentRow + ' L' + width + ' ' + currentRow;
            currentRow += lineHeight;
        }
        return path;
    }

    updateElementTextPaths(element: any, width: number, height: number, lineHeight: number, xOffset: number, yOffset: number) {
        let hourPath = 'M'+xOffset+' ' + (lineHeight + yOffset) + ' L' + width + ' ' + (lineHeight + yOffset);
        element.select('.hour-path').attr('d',hourPath);
    }

    generateTitleText(element: any, text: string, color: string, width: number, height: number, xOffset: number, yOffset: number, yBorderBottom: number) {
        // remove the previous text
        element.selectAll('.event-title-line').remove();
        
        // update the clipPath
        element.select('clipPath rect').attr('width',width-8);

        let availablewidth = Math.floor(width/ this.LETTER_MEAN_EVENT_WIDTH);
        let ypos = yOffset;
        let subStringStart = 0;
        let titleLength = text.length;
        let clipId = element.select('clipPath').attr('id');
        
        while(ypos < (height - yBorderBottom) && (subStringStart ===0 || subStringStart < titleLength)) {
            element.append('text')
                .text(text.substr(subStringStart, availablewidth))
                .attr('fill',color)
                .attr('font-family', 'sans-serif')
                .attr('font-size', Math.ceil(this.eventFontSize)+'px')
                .attr('x',xOffset)
                .attr('y', ypos)
                .attr('class','event-title-line')
                .attr('clip-path','url(#'+clipId+')');
            ypos += this.eventFontSize;
            subStringStart += availablewidth;
        }
    }

    drawClosedHeader(eventsMap) {
        let me = this;
        let headerHeight = this.config.headerHeight + 60;
        this.daysHeaders.attr('style','height: '+headerHeight+'px;');
        this.headerEvents.attr('height',60);
        $('.calendar-grid-container:visible').css('top',headerHeight+'px');
        $('.calendar-grid-container:visible').css('height',(this.calendarInterface.getHeight()-headerHeight)+'px');

        this.headerEvents.select('.extra-day-events').remove();
        let extraEventGroup = this.headerEvents.append('g')
            .attr('class','extra-day-events');
        let counter = 0;
        eventsMap.forEach(function(value,key) {
            let thisDayEvents = value;
            let slotInfo = me.getSlotInfo(thisDayEvents);
            if(slotInfo.lastUsedSlot >= 2) {
                let xpos = 55 + me.colSize * counter;
                let group = extraEventGroup.append('g');
                group.append('rect')
                    .attr('x',xpos)
                    .attr('y',43)
                    .attr('rx',5)
                    .attr('ry',5)
                    .attr('width',40)
                    .attr('height',15)
                    .style('fill','#aaa');
                group.append('text')
                    .text('+ '+slotInfo.extraEvents)
                    .attr('x',xpos+20)
                    .attr('y',55)
                    .attr('width',30)
                    .attr('text-anchor','middle')
                    .attr('fill','#fff');
            }
            counter++;
        });
        this.headerEvents.select('.overflow-events').style('visibility', 'hidden');
        this.headerEvents.select('.extra-day-events').selectAll('g').on('click',this.toggleHeaderState.bind(this));
        $(this.toggleHeaderStateButton.node()).show();
    }

    getSlotInfo(slotsArray): any{
        let extraEvents = 0;
        let lastUsedSlot = 0;
        for(let i in slotsArray) {
            if(slotsArray[i] !== undefined) {
                lastUsedSlot = parseInt(i);
                if(parseInt(i) >= 2) {
                    extraEvents++;
                }
            }
        }
        return {extraEvents: extraEvents, lastUsedSlot: lastUsedSlot}
    }

    toggleHeaderState() {
        let headerHeight = 0;
        if(this.toggleState === 'closed') {
            this.svgToogleButton.select('#arrow-up').style('display','block');
            this.svgToogleButton.select('#arrow-down').style('display','none');
            this.headerEvents.select('.extra-day-events').style('visibility', 'hidden');
            this.headerEvents.select('.overflow-events').style('visibility', 'visible');
            this.toggleState = 'opened';
            headerHeight = this.config.headerHeight + (this.overallMax + 1) * 20;
            this.headerEvents.attr('height',(this.overallMax + 1) * 20);
        } else {
            this.svgToogleButton.select('#arrow-down').style('display','block');
            this.svgToogleButton.select('#arrow-up').style('display','none');
            this.headerEvents.select('.extra-day-events').style('visibility', 'visible');
            this.headerEvents.select('.overflow-events').style('visibility', 'hidden');
            this.toggleState = 'closed';
            headerHeight = this.config.headerHeight + 60;
            this.headerEvents.attr('height',60);
        }
        this.daysHeaders.attr('style','height: '+headerHeight+'px;');
        $('.calendar-grid-container:visible').css('top',headerHeight+'px');
        $('.calendar-grid-container:visible').css('height',(this.calendarInterface.getHeight()-headerHeight)+'px');
    }

    initialiseFulldayEventsMap(maxEvents: number) {
        let fullDayEvents = new Map();
        let dayCounter = moment(this.currentDate).startOf('date');
        while(dayCounter <= this.endDate) {
            let key: string = moment(dayCounter).format('YYYYMMDD');
            let currentDaySlots = this.buildDaySlots(maxEvents);
            fullDayEvents.set(key, currentDaySlots);
            dayCounter.add(1,'days');
        }

        return fullDayEvents;
    }

    switchWeek(direction: number) {
        this.currentDate.add(direction,'week');
        this.endDate = moment(this.currentDate).add(6, 'days').endOf('date');
        this.currentDate.locale(this.config.locale);
        this.endDate.locale(this.config.locale);
        this.toggleState = 'closed';
        this.calendarInterface.setStart(this.currentDate);
        this.calendarInterface.setEnd(this.endDate);
        this.calendarInterface.triggerEvent('weekChanged', [this.currentDate]);
        this.weekRequestNewData();
        this.buildCurrentWeek();
    }

    addClickEventHandler(svgElement: any, currentEvent: any) {
        let me = this;
        svgElement.on('dblclick',function(e) {
            me.calendarInterface.triggerEvent('openEvent', [currentEvent]);
            d3.event.stopPropagation();
        });

        svgElement.on('click', function() {
            d3.event.stopPropagation();
            d3.event.preventDefault();
            me.selectEvent(currentEvent, svgElement)
        });

        svgElement.on('contextmenu', function() {
            d3.event.stopPropagation();
            d3.event.preventDefault();
            me.selectEvent(currentEvent, svgElement);
            me.calendarInterface.triggerEvent('eventMenu', [currentEvent, d3.event]);
        });
    }

    selectEvent(currentEvent, svgElement) {
        if(this.selectedEvent && this.selectedEvent.id === currentEvent.getId()) {
            // do nothing
        } else {
            if(this.selectedEvent) {
                this.selectedEvent.svg.style('stroke','#FFF');
                $(this.selectedEvent.svg.node()).parent().removeClass('selected');
            } else {
                $(this.svgRoot.node()).addClass('event-selected');
                $(this.headerEvents.node()).addClass('event-selected');
            }
            this.selectedEvent = {id: currentEvent.getId(), svg: svgElement.select('rect'), start: currentEvent.getStart(), end: currentEvent.getEnd()};
            this.calendarInterface.triggerEvent('eventSelected', [currentEvent]);
            this.selectedEvent.svg.style('stroke','#333');
            $(this.selectedEvent.svg.node()).parent().addClass('selected');
        }
    }

    addUnselectEventHandler(svgElement: any, removeTime?: boolean) {
        if(removeTime === undefined) {
            removeTime = false;
        }
        let me = this;
        svgElement.on('click', function() {
            let src = svgElement.node().parentElement;
            if(me.selectedEvent && !(src.classList.contains('calendar-day-event') || src.classList.contains('calendar-fullday-event'))) {
                d3.event.stopPropagation();
                d3.event.preventDefault();
                me.deselectEvent();
            }
        });

        svgElement.on('contextmenu', function() {
            d3.event.stopPropagation();
            d3.event.preventDefault();
            let event = d3.event;
            let src = svgElement.node().parentElement;
            if(me.selectedEvent && !(src.classList.contains('calendar-day-event') || src.classList.contains('calendar-fullday-event'))) {
                me.deselectEvent();
            }
            let clickDate = me.getDateFromMousePosition({x:event.offsetX, y:event.offsetY}, false);
            if(removeTime) {
                clickDate = clickDate.startOf('day');
            }
            me.calendarInterface.triggerEvent('backgroundMenu', [clickDate, d3.event]);
        });
    }

    deselectEvent() {
        this.selectedEvent.svg.style('stroke','#FFF');
        $(this.selectedEvent.svg.node()).parent().removeClass('selected');
        this.selectedEvent = undefined;
        this.calendarInterface.triggerEvent('eventUnselected',null);
        $(this.svgRoot.node()).removeClass('event-selected');
        $(this.headerEvents.node()).removeClass('event-selected');
    }

    getEventPopupTitle(event: SAIEvent, displayMode: string) : string {
        let popupTitle = '';
        if(displayMode === 'FULL') {
            popupTitle += event.getStart().format('DD/MM/YYYY HH:mm') + ' - ' + event.getEnd().format('DD/MM/YYYY HH:mm') + '\n';
        } else if(displayMode == 'HOURS') {
            popupTitle += event.getStart().format('HH:mm') + ' - ' + event.getEnd().format('HH:mm') + '\n';
        }

        let eventPopupTitle = event.getPopupTitle();
        if(eventPopupTitle !== undefined && eventPopupTitle !== '') {
            popupTitle += eventPopupTitle;
        } else {
            popupTitle += event.getTitle();
        }
        return popupTitle;
    }

    handleCalendarMouseDown(isHeader: boolean, event: JQueryMouseEventObject) {
        if(event.ctrlKey) {
            this.startEventCreation = true;
            this.newEventFromHeader = isHeader;
            this.newEventStartDate = this.getDateFromMousePosition(this.getEventCoordinates(event), false, !isHeader);
            if(isHeader) {
                this.newEventStartDate.startOf('day');
            }
            let overlay = $('<div>').addClass('newEventOverlay');
            $('body').append(overlay);
            overlay.on('mouseup',this.handleCalendarMouseUpJquery.bind(this));
            overlay.on('mousemove', this.handleCalendarMouseMoveJquery.bind(this));
        }
    }

    // Fix to handle the coordinates over the current-day display
    getEventCoordinates(event: JQueryMouseEventObject) : {x:number, y:number} {
        let x = event.clientX - event.currentTarget.getBoundingClientRect().left;
        let y = event.clientY - event.currentTarget.getBoundingClientRect().top;
        return {x,y};
    }

    handleCalendarMouseUpJquery(event: JQueryMouseEventObject) {
        if(this.startEventCreation) {
            this.handleCalendarMouseUp({x:event.offsetX, y:event.offsetY}, event.target);
        }
    }

    handleCalendarMouseUp(mousePosition: {x: number, y:number}, target: any) : void {
        this.startEventCreation = false;
        let newEventEndDate = this.getDateFromMousePosition(mousePosition, true);

        if(this.newEventFromHeader) {
            newEventEndDate.endOf('day');
        }

        if(this.newEventStartDate.isBefore(newEventEndDate)) {
            this.calendarInterface.triggerEvent('newEvent', [this.newEventStartDate,newEventEndDate]);
        } else {
            this.calendarInterface.triggerEvent('newEvent', [newEventEndDate,this.newEventStartDate]);
        }           
        // trigger new event
        $('.newEventDrag').remove();
        this.newEventsDraw={};
        this.newFullDayEventDraw = undefined;
        target.remove();
        this.hoursDisplay.remove();
        this.hoursDisplay = undefined;
    }

    handleCalendarMouseMoveJquery(event: JQueryMouseEventObject) {
        if(this.startEventCreation) {
            this.handleCalendarMouseMove({x:event.pageX, y:event.pageY});
        }
    }

    handleCalendarMouseMove(mousePosition: {x:number, y:number}) {
        let currentEventEnd = this.getDateFromMousePosition(mousePosition, true);
        this.mousePosition = mousePosition;
        if(this.newEventFromHeader) {
            currentEventEnd.endOf('day');
        }
        let realStart = this.newEventStartDate.isBefore(currentEventEnd) ? this.newEventStartDate : currentEventEnd;
        let realEnd = this.newEventStartDate.isBefore(currentEventEnd) ? currentEventEnd : this.newEventStartDate;
        this.displayRectangle(realStart,realEnd, this.newEventFromHeader, mousePosition);
        this.showCurrentDate(this.newEventFromHeader);
        this.setHoursDisplayPosition(mousePosition.x, mousePosition.y);
    }

    getDateFromMousePosition(position:{x: number, y:number}, isGlobal: boolean, withSvgOffset?: boolean) : moment.Moment {
        let yOffset = position.y;
        let xOffset = position.x - (withSvgOffset === undefined || withSvgOffset === true ? (this.colSize*7) : 0);
        if(isGlobal) {
            yOffset -= $(this.svgRoot.node()).offset().top;
            xOffset -= $(this.svgRoot.node()).offset().left;
        }
        let minutes = Math.min(Math.max(0,Math.floor(yOffset * 60 / this.rowSize)), 60*24);
        minutes = this.getGridSnap(minutes,'min');
        let day = Math.floor((xOffset -50) / this.colSize);
        let newEventDate = moment(this.currentDate).minutes(minutes).add(day,'days');
        return newEventDate   
    }

    getXPositionFromEvent(event) {
        let xStart = Math.floor((event.offsetX -50) / this.colSize) * this.colSize + 50;
        let xEnd = Math.floor((event.offsetX -50) / this.colSize) * this.colSize + 50 + this.colSize;
        return {xStart: xStart, xEnd: xEnd};
    }

    private static GRID_SNAP_VALUE = 5; // 5 minutes snap
    getGridSnap(posY: number, type: string) {
        let fiveMinPx = SAICalendarWeekRenderer.GRID_SNAP_VALUE * (type === 'px' ? this.rowSize/60 : 1);
        let extra = posY % fiveMinPx; // Height of 5mn in px
        return extra >= 3 ? posY + (fiveMinPx-extra) : posY - extra;
    }

    setHoursDisplayPosition(x:number, y: number) {
        let displayWidth = this.hoursDisplay.outerWidth();
        let displayHeight = this.hoursDisplay.outerHeight();
        let xPos = x + 10;
        if((xPos + displayWidth) > $(window).width()) {
            xPos = x - displayWidth;
        }
        let yPos = y + 15;
        if((yPos + displayHeight) > $(window).height()) {
            yPos = y - displayHeight;
        }
        this.hoursDisplay.css('top',yPos);
        this.hoursDisplay.css('left',xPos);
    }

    handleCtrlKeyDown(event: JQueryKeyEventObject) {
        if($(this.rootElement.node()).find('.calendar-week:visible').length > 0) {
            if(event.ctrlKey && $('.calendar-week:visible').length > 0) {
                this.showCurrentDate(this.isMouseOverHeader(event));
            }
        }
    }

    hideCurrentDate(event: JQueryKeyEventObject) {
        if($(this.rootElement.node()).find('.calendar-week:visible').length > 0) {
            if(this.hoursDisplay && !this.startEventCreation) {
                this.hoursDisplay.remove();
                this.hoursDisplay = undefined;
            }

            // Ctrl + C
            if(event.ctrlKey && event.which === 67) {
                this.copiedEvent = this.selectedEvent;
                this.calendarInterface.triggerEvent('eventCopy', [this.selectedEvent, Math.random()]);
            }
            // Ctrl + V
            if(event.ctrlKey && event.which === 86) {
                if(this.copiedEvent) {
                    let newEventStart = this.getDateFromMousePosition({x:this.mousePosition.x, y:this.mousePosition.y}, true);
                    let diff = newEventStart.diff(this.copiedEvent.start, 'minutes');
                    let newEventEnd = this.copiedEvent.end.add(diff, 'minutes');
                    this.calendarInterface.triggerEvent('eventPaste', [newEventStart,newEventEnd]);
                }
            }
        }
    }

    handleGlobalMousePosition(event: JQueryMouseEventObject) {
        if($(this.rootElement.node()).find('.calendar-week:visible').length > 0) {
            if($('.calendar-week:visible').length > 0) {
                this.mousePosition = {x:event.pageX, y:event.pageY};
                if(event.ctrlKey && !this.startEventCreation) {
                    this.showCurrentDate(this.isMouseOverHeader(event));
                } else if(this.hoursDisplay && !this.startEventCreation) {
                    this.hoursDisplay.remove();
                    this.hoursDisplay = undefined;
                }
            }
        }
    }

    showCurrentDate(isHeader ?: boolean) {
        let currentDate = this.getDateFromMousePosition(this.mousePosition,true);
        if(!this.hoursDisplay) {
            this.hoursDisplay = $('<div>').addClass('hoursDisplay').appendTo($(this.calendarRoot.node()));
        }
        let format = isHeader ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm';
        this.hoursDisplay.text(currentDate.format(format));
        this.setHoursDisplayPosition(this.mousePosition.x, this.mousePosition.y);
    }

    getPositionFromDate(date: moment.Moment): {x:number, y:number} {
        return {
            y : (date.minutes() + (date.hours() * 60)) * (this.rowSize / 60),
            x : moment(date).startOf('day').diff(moment(this.currentDate).startOf('day'),'day') * this.colSize + 50 + (7*this.colSize)
        };
    }

    getDayRectangle(date: moment.Moment) {
        let key = date.format();
        if(!this.newEventsDraw[key]) {
            let eventStart = moment(date).startOf('day');
            let eventEnd = moment(date).endOf('day');
            let startPos = this.getPositionFromDate(eventStart);
            let endPos = this.getPositionFromDate(eventEnd);
            let element = this.svgRoot.append('rect')
            .attr('x',startPos.x)
            .attr('y',startPos.y)
            .attr('width',this.colSize)
            .attr('height',endPos.y - startPos.y)
            .attr('class','newEventDrag newEventDragHidden');
            this.newEventsDraw[key] = {
                start: eventStart,
                end: eventEnd,
                element: element
            };
        }
        return this.newEventsDraw[key];
    }

    getFullDayRectangle(start: moment.Moment, end: moment.Moment, mousePositon:{x: number, y:number}) {
        let startPos = this.getPositionFromDate(start);
        let width = (end.diff(start,'days')+1) * this.colSize;
        if(!this.newFullDayEventDraw) {
            let yPos = Math.floor((mousePositon.y - $(this.headerEvents.node()).offset().top) / 20) * 20 + 1;
            this.newFullDayEventDraw = this.headerEvents.append('rect')
            .attr('x',(startPos.x- 7*this.colSize))
            .attr('y',yPos)
            .attr('width',width)
            .attr('height',18)
            .attr('class','newEventDrag');
        } else {
            this.newFullDayEventDraw.attr('x',(startPos.x- 7*this.colSize))
            .attr('width',width)
            .attr('height',18)
        }
    }

    displayRectangle(start: moment.Moment, end: moment.Moment, isHeader: boolean, mousePositon:{x: number, y:number}) {
        if(isHeader) {
            this.getFullDayRectangle(start, end, mousePositon);
        } else {
            for(let i=0; i < 7; i++) {
                let currentDate = moment(this.currentDate).add(i,'day').startOf('day');
                let currentDateRect = this.getDayRectangle(currentDate);
                if(currentDateRect.start.isAfter(end) || currentDateRect.end.isBefore(start)) {
                    currentDateRect.element.classed('newEventDragHidden', true);
                } else {
                    currentDateRect.element.classed('newEventDragHidden', false);
                    let startPos;
                    if(start.day() === currentDate.day()){
                        let pos = this.getPositionFromDate(start);
                        currentDateRect.element.attr('y', pos.y);
                        startPos = pos.y;
                    } else {
                        let pos = this.getPositionFromDate(currentDate);
                        currentDateRect.element.attr('y', pos.y);
                        startPos = pos.y;
                    }
                    if(end.day() === currentDate.day()){
                        let pos = this.getPositionFromDate(end);
                        currentDateRect.element.attr('height', pos.y - startPos);
                    } else {
                        let pos = this.getPositionFromDate(moment(currentDate).endOf('day'));
                        currentDateRect.element.attr('height', pos.y - startPos);
                    }
                }
            }
        }
    }

    isMouseOverHeader(event: any) {
        return event.target === this.headerEvents.node() || $(event.target).parents('.calendarHeader').length > 0;
    }

    private drawCurrentTimeLine() {
        this.svgRoot.selectAll('.current-time-display').remove();
        let now = moment();
        if(now.isAfter(this.currentDate) && now.isBefore(this.endDate)) {
            let posX = moment().startOf('day').diff(this.currentDate,'days') * this.colSize + 50;
            let posY = this.rowSize * (now.diff(moment().startOf('day'),'hours',true));

            let currentEventSvg = this.svgRoot.append('g')
                .attr('class','current-time-display')
                .attr('transform','translate('+(posX + (7*this.colSize))+','+posY+')');

            currentEventSvg.append('line')
                .attr('x1','0')
                .attr('y1','0')
                .attr('x2',this.colSize)
                .attr('y2','0');
        }
    }

    private drawCurrentDayBackground() {
        
        this.svgRoot.selectAll('.current-day-background').remove();
        this.headerEvents.selectAll('.current-day-background').remove();
        let now = moment();
        if(now.isAfter(this.currentDate) && now.isBefore(this.endDate)) {
            let posX = moment().startOf('day').diff(this.currentDate,'days') * this.colSize + 50 + this.colSize*7;
            let posY = 0;

            let currentEventSvg = this.svgRoot.insert('g','.background-events')
                .attr('class','current-day-background')
                .attr('transform','translate('+posX+','+posY+')');

            currentEventSvg.append('rect')
            .attr('x',0)
            .attr('y',0)
            .attr('width',this.colSize)
            .attr('height',24 * this.rowSize);

            let headerSvg = this.headerEvents.append('g')
                .attr('class','current-day-background')
                .attr('transform','translate('+posX+','+posY+')');
            
            headerSvg.append('rect')
                .attr('x',0)
                .attr('y',0)
                .attr('width',this.colSize)
                .attr('height','100%');
        }
    }

    private buildCalendarOptions() {
        if(this.config.showTopMenu) {
            this.buildTodayButton();
            let previousWeekButton = this.calendarOptionsDiv.append('div')
                .attr('class','week-change-button previous options-button');
            let previousWeekSvg = previousWeekButton.append('svg')
                .attr('width','30')
                .attr('height','30');

            previousWeekSvg.append('circle')
                .attr('cx','15')
                .attr('cy','15')
                .attr('r','14')
                .attr('style','stroke: none;')
                .attr('class','toggle-button-circle');

            previousWeekSvg.append('path')
                .attr('d','m 15.4,26.5 -9,-10.7 v -1.5 l 9,-10.8 3.1,3.3 -6.8,8.2 6.8,8.2 z')
                .attr('style','fill: #999; stroke: none;');
            previousWeekSvg.on('click', this.switchWeek.bind(this,-1));

            let nextWeekButton = this.calendarOptionsDiv.append('div')
                .attr('class','week-change-button next options-button');
            let nextWeekSvg = nextWeekButton.append('svg')
                .attr('width','30')
                .attr('height','30');

            nextWeekSvg.append('circle')
                .attr('cx','15')
                .attr('cy','15')
                .attr('r','14')
                .attr('style','stroke: none;')
                .attr('class','toggle-button-circle');

            nextWeekSvg.append('path')
                .attr('d','M13.5 26.5 L22.5 15.8 L22.5 14.3 L13.5 3.5 L10.4 6.8 L17.2 15 L10.4 23.2 Z')
                .attr('style','fill: #999; stroke: none;');

            nextWeekSvg.on('click', this.switchWeek.bind(this,1));

            this.buildCurrentWeek();
            if(this.config.showOptions) {
                this.buildParametersButton();
            }
            if(this.config.showModeSwitch) {
                this.buildDisplayTypeMenu();
            }
        }
    }

    private buildTodayButton() {
        let todayContainer = this.calendarOptionsDiv.append('div')
            .attr('class','options-button today-button');
        
        todayContainer.append('svg')
            .attr('width','40')
            .attr('height','40')
            .append('circle')
            .attr('cx','20')
            .attr('cy','20')
            .attr('r','20')
            .attr('style','stroke: none;')
            .attr('class','toggle-button-circle');

        todayContainer.append('img')
            .attr('src',require('./images/calendar-today.svg'))
            .attr('width', 30)
            .attr('height',30);

        todayContainer.on('click', this.moveToToday.bind(this));
    }

    private buildCurrentWeek() {
        if(this.currentWeekDisplay === undefined) {
            this.currentWeekDisplay = this.calendarOptionsDiv.append('div')
                .attr('class','current-month');
        }
        if(this.currentDate !== undefined && this.endDate !== undefined) {
            this.endDate.locale(this.config.locale);
            let dateDisplay;
            dateDisplay = this.currentDate.year() === this.endDate.year() ? this.currentDate.format('DD MMM') : this.currentDate.format('DD MMM YY');
            dateDisplay += ' - ';
            dateDisplay += this.endDate.format('DD MMM YY');
            this.currentWeekDisplay.text(dateDisplay);
        }
    }

    moveToToday() {
        let targetDate = moment().locale(this.config.locale).startOf('week');
        if(!this.currentDate.isSame(targetDate)) {
            this.currentDate = targetDate;
            this.moveToDate();
        }
    }

    moveToDate() {
        this.endDate = moment(this.currentDate).add(6, 'days').endOf('date');
        this.currentDate.locale(this.config.locale);
        this.endDate.locale(this.config.locale);
        this.toggleState = 'closed';
        this.calendarInterface.setStart(this.currentDate);
        this.calendarInterface.setEnd(this.endDate);
        this.calendarInterface.triggerEvent('weekChanged', [this.currentDate]);
        this.weekRequestNewData();
        this.buildCurrentWeek();
        this.svgRoot.style('transform','translate(-'+(this.colSize*7)+'px,0)');
        this.svgRoot.attr('dx',-(7*this.colSize));
    }

    private buildParametersButton() {
        let optionsContainer = this.calendarOptionsDiv.append('div')
            .attr('class','options-button parameters-button');
        
        optionsContainer.append('svg')
            .attr('width','40')
            .attr('height','40')
            .append('circle')
            .attr('cx','20')
            .attr('cy','20')
            .attr('r','20')
            .attr('style','stroke: none;')
            .attr('class','toggle-button-circle');

        optionsContainer.append('img')
            .attr('src',require('./images/options.svg'))
            .attr('width', 30)
            .attr('height',30);
    }

    buildDisplayTypeMenu() {
        let displaySelection = this.calendarOptionsDiv.append('div')
            .attr('class','display-selection')
        
        displaySelection.append('div')
            .attr('class','display-selection-label')
            .text('Semaine');
        
        let arrow = displaySelection.append('svg')
            .attr('width','30')
            .attr('height','30');

        arrow.append('path')
            .attr('d','m 3.5,14 10.7,9 h 1.5 L 26.5,14 23.2,10.9 15,17.7 6.8,10.9 Z')
            .attr('style','fill: #999; stroke: none;');
        
        displaySelection.on('click', this.toggleMenu.bind(this));

        let selectPos = $(displaySelection.node()).offset();
        selectPos.top = selectPos.top + 40

        let otherTypes = this.calendarOptionsDiv.append('div')
            .attr('class','display-selection-menu');
        
        for(let modeIdx in this.config.availableModes) {
            let mode = this.config.availableModes[modeIdx];
            otherTypes.append('div')
                .attr('class', 'item')
                .text(this.calendarInterface.getConfigLabel(mode))
                .attr('data-id',mode);
        }
        
        let fade = this.calendarOptionsDiv.append('div')
            .attr('class', 'fade');
        fade.on('click', this.toggleMenu.bind(this));

        this.calendarOptionsDiv.selectAll('.item').on('click', this.handleModeChange.bind(this));
    }

    toggleMenu() {
        let menu = $(this.calendarOptionsDiv.select('.display-selection-menu').node());
        let fade = $(this.calendarOptionsDiv.select('.fade').node());
        if(menu.is(':visible')) {
            menu.hide();
            fade.hide();
        } else {
            let menuItem = $(this.calendarOptionsDiv.select('.display-selection').node()); 
            let menupos = menuItem.offset();
            let parentPos = menuItem.offsetParent().offset();
            menu.css('left',menupos.left-parentPos.left);
            menu.show();
            fade.show();
        }
    }

    handleModeChange() {
        this.toggleMenu();
        let clickedItem = arguments[1];
        let nodeList = arguments[2];
        let selectedMode = $(nodeList[clickedItem]).data('id');
        if(selectedMode !== SAIEventInterfaceDisplayMode.WEEKS) {
            this.calendarInterface.resetInterface(this.currentDate,selectedMode);
        }
    }
}

export { SAICalendarWeekRenderer };
