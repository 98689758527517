import { SAIEventInterface } from './sai-event-interface';
import * as moment from 'moment';

type EventFetchFunction = (period: SAITimePeriod, eventArray: Array<SAIEvent>) =>void;
type GetCardLabelsFunction = (moment: moment.Moment, labels:any) => void;

class SAITimePeriod {
    private start: moment.Moment;
    private end: moment.Moment;
    private fetched: boolean;
    private events: Array<SAIEvent> ;

    constructor(start: moment.Moment, end:moment.Moment) {
        this.start = start;
        this.end = end;
        this.fetched = false;
    }

    setFetched(events: Array<SAIEvent>){
        this.fetched = true;
        this.events = events;
    }

    isValid() : boolean{
        return this.start.isValid() && this.end.isValid() && this.start.isSameOrBefore(this.end);
    }

    isEmpty() : boolean{
        return this.start.isSame(this.end);
    }

    getStart() : moment.Moment{
        return this.start;
    }

    getEnd() : moment.Moment{
        return this.end;
    }

    canBeMergedWith(other: SAITimePeriod) : boolean{
        //The given period cane be merged with the current one if the period touches the current one
        return other.getStart().isSameOrBefore(this.getEnd()) && other.getEnd().isSameOrAfter(this.getStart());
    }

    leftOverlapsWith(other: SAITimePeriod) : boolean{
        //This period touches the other one from the left
        //
        //     |________|
        //  |xxxx|
        //
        return this.getStart().isBefore(other.getStart()) && this.getEnd().isAfter(this.getStart());
    }

    rightOverlapsWith(other: SAITimePeriod) : boolean{
        //This period touches the other one from the right
        //
        //     |________|
        //            |xxxx|
        //
        return this.getStart().isBefore(other.getEnd()) && this.getEnd().isAfter(other.getEnd());        
    }

    isFetched(): boolean{
        return this.fetched === true;
    }
}

enum SAIEventPosition {
    foreground = 'F',
    background = 'B'
}

class SAIEvent {
    private id: string;
    
    private start: moment.Moment;
    private end: moment.Moment;
    
    private data: any;
    private color: string;
    private title: string;
    private popupTitle: string;
    private colorLegend: string;
    private position: string;
    private description: string;

    private wellRendered: boolean;
    private validRender: boolean

    private element: JQuery<HTMLElement>;
    
    constructor(id: string, start: moment.Moment, end: moment.Moment, data: any) {
        this.id = id;
        this.start = start;
        this.end = end;
        this.data = data;
        this.wellRendered = false;

        if (!start.isValid()) {
            console.warn('invalid start date for event : ' + start);
        }

        if (!end.isValid()) {
            console.warn('invalid end date for event : ' + end);
        }
    }

    getId(): string {
        return this.id ? this.id : null;
    }

    getElement() : JQuery<HTMLElement> {
        return this.element ? this.element : null;
    }

    getStart() : moment.Moment {
        return this.start ? this.start : null;
    }

    getTitle() : string {
        return this.title;
    }

    setTitle(title : string ) : void {
        this.title = title;
    }

    getPopupTitle() : string {
        return this.popupTitle;
    }

    setPopupTitle(popupTitle: string) {
        this.popupTitle = popupTitle;
    }

    getEnd() : moment.Moment {
        return this.end ? this.end : null;
    }

    getData() : any {
        return this.data ? this.data : null;
    }

    setElement(newElement: JQuery<HTMLElement>){
        this.element = newElement;
        this.wellRendered = true;
    }

    getColor() : string {
        return this.color;
    }

    setColor(color: string) : void {
        this.color = color;
    }

    getColorLegend() : string {
        return this.colorLegend;
    }

    setColorLegend(legend: string) : void {
        this.colorLegend = legend;
    }

    setDescription(description: string) : void {
        this.description = description;
    }

    getDescription() : string {
        return this.description;
    }

    merge(newEvent: SAIEvent){
        this.start = newEvent.getStart();
        this.end = newEvent.getEnd();
        this.data = newEvent.getData();
        this.validRender = false;
        this.position = newEvent.getPosition();
        this.title = newEvent.getTitle();
        this.description = newEvent.getDescription();
    }

    isWellRendered(): boolean{
        return this.wellRendered === true;
    }

    setPosition(position:string) {
        this.position = position;
    }

    getPosition():string {
        return this.position !== undefined ? this.position : SAIEventPosition.foreground;
    }

    public clone(): SAIEvent {
        let newEvent = new SAIEvent(this.id, this.start, this.end, this.data);
        newEvent.setColor(this.color);
        newEvent.setTitle(this.title);
        newEvent.setPopupTitle(this.popupTitle);
        newEvent.setColorLegend(this.colorLegend);
        newEvent.setPosition(this.position);
        newEvent.setDescription(this.description);
        return newEvent;
    }

    public setStart(start) {
        this.start = start;
    }

    public setEnd(end) {
        this.end = end;
    }
}

class SAIEventManager {
    private fetchEventFunction: (period: SAITimePeriod, callback : EventFetchFunction ) =>void;
    private getCardLabelsFunction: (moment: moment.Moment, callback : GetCardLabelsFunction) => void;
    private lazyRetrieval: boolean;
    private eventsMap: {[evtPosition: string] : {[evtId: string]: SAIEvent}};
    private currentPeriods: Array<any>;
    private renderer: SAIEventInterface;

    constructor(options : { fetchEvent? : (period: SAITimePeriod, callback : EventFetchFunction ) =>void, lazyRetrieval?: boolean, getCardLabels? : (moment: moment.Moment, callback : GetCardLabelsFunction) => void }) {

        this.fetchEventFunction = options.fetchEvent || function() {};
        this.lazyRetrieval = options.lazyRetrieval !== undefined? options.lazyRetrieval : true;
        this.getCardLabelsFunction = options.getCardLabels || function() {};

        this.eventsMap = {};

        this.currentPeriods = [];
    }

    setRenderer(renderer : SAIEventInterface){
        this.renderer = renderer;
    }

    private getPeriodsToFetch(remainingPeriod: SAITimePeriod, startAtIndex: number) : Array<SAITimePeriod>{
        let toFetchPeriods: Array<SAITimePeriod> = [];

        if(! (remainingPeriod instanceof SAITimePeriod)){
            console.warn('Provided period must be of type SAITimePeriod. As it\'s not the case, it will be discarded');
            return [];
        }else{
            if(! remainingPeriod.isValid()){
                return [];
            }

            if(remainingPeriod.getStart().isSame(remainingPeriod.getEnd())) {
                return [];
            }


            if(this.currentPeriods.length === 0 || ! this.lazyRetrieval){
                this.currentPeriods = [];
                return [remainingPeriod];
            }else{
                //Simple base case that handles periods after or before the existing ones
                if(remainingPeriod.getEnd().isSameOrBefore(this.currentPeriods[0].getStart())){
                    //Fully ahead period, cannot be merged
                    //
                    //         |______| |________|
                    // |xxxxx|
                    //
                    return [remainingPeriod];
                }else if(remainingPeriod.getStart().isSameOrAfter(this.currentPeriods[this.currentPeriods.length - 1].getEnd())){
                    //Fully further in time period, cannot be merged
                    //
                    // |______| |________|
                    //                   |xxxx|
                    //
                    return [remainingPeriod];
                }else{
                    // We need to compute which sub-periods must be fetched as the new period might overlap one
                    // or multiple already fetched periods.
                    //
                    // |_____|    |______| |________|
                    //
                    // We've to insert our new period. We first compute the part that is missing
                    //
                    // |_____|    |______| |________|
                    //      |xxxxxxxxxxxxxx|
                    //
                    // |_____|    |______| |________|
                    //       |xxxx|xxxxxxxx|
                    //
                    //

                    for(let i = startAtIndex; i < this.currentPeriods.length; i++){
                        let existingPeriod = this.currentPeriods[i];
                        if(remainingPeriod.getStart().isSameOrAfter(existingPeriod.getStart()) &&
                            remainingPeriod.getEnd().isSameOrBefore(existingPeriod.getEnd())) {
                            // |________|
                            //  |xxxxx|
                            return [];
                        }else if(remainingPeriod.leftOverlapsWith(existingPeriod)){
                            //     |________|
                            //  |xxxxx|
                            //We've to get the part to fetch
                            let newSub = new SAITimePeriod(remainingPeriod.getStart(), existingPeriod.getStart());
                            toFetchPeriods.push(newSub);

                            remainingPeriod = new SAITimePeriod(existingPeriod.getEnd(), remainingPeriod.getEnd());
                            if(remainingPeriod.getEnd().isAfter(existingPeriod.getEnd())) {
                                //The event goes further than the current period.
                                //We recompute the remaining part and keep working
                                //on it
                                //     |________|
                                //  |xx|        |xx|
                                remainingPeriod = new SAITimePeriod(existingPeriod.getStart(), remainingPeriod.getEnd());
                                let nextSubs = this.getPeriodsToFetch(remainingPeriod, i+1);
                                return toFetchPeriods.concat(nextSubs);
                            } else {
                                return toFetchPeriods;
                            }
                        } else if(remainingPeriod.rightOverlapsWith(existingPeriod)) {
                            return this.getPeriodsToFetch(new SAITimePeriod(existingPeriod.getEnd(), remainingPeriod.getEnd()), i+1);
                        }
                    }

                    //If we're here then the requested period does not conflict with
                    //any existing one
                    return [remainingPeriod];
                }
            }
        }
    }

    changePeriod(newPeriod: SAITimePeriod) : boolean{
        let periodsToFetch = this.getPeriodsToFetch(newPeriod, 0);
        this.currentPeriods = this.currentPeriods.concat(periodsToFetch);
        this.currentPeriods = this.currentPeriods.sort((a,b) => {
            return a.getStart().format('YYYY-MM-DD HH:mm:ss').localeCompare(b.getStart().format('YYYY-MM-DD HH:mm:ss'));
        });
        if(this.fetchEventFunction){
            //If we're not lazy, we reset every known event
            if(!this.lazyRetrieval) {
                this.eventsMap = {};
            }

            for(let periodKey in periodsToFetch){
                var period = periodsToFetch[periodKey];
                this.fetchEventFunction(period, this.onEventFetched.bind(this));
            }
            return true;
        }else{
            console.warn('No fetching function provided. The system can\'t fetch the given period events');
            return false;
        }
    }

    setFetchFunction(fetcher: (period: SAITimePeriod, callback : EventFetchFunction ) =>void){
        this.fetchEventFunction = fetcher;
    }

    onEventFetched(period: SAITimePeriod, eventArray: Array<SAIEvent>) {
        //Some events will have to be reset as they are given in the result again
        //which implies that they might have been modified
        period.setFetched(eventArray);
        this.eventsMap = {};
        for (let event in eventArray) {
            let treatingEvent = eventArray[event];
            if (!(treatingEvent instanceof SAIEvent)) {
                console.warn('Provided event must be of type SAIEvent. As it\'s not the case, it will be discarded');
                continue;
            } else if (treatingEvent.getId() === null) {
                //no id provided in the event, we can't possibly know if it already exists
                console.warn('Provided event must be identified by an id. As it\'s not the case, it will be discarded');
                continue;
            } else {
                this.addEventToMap(treatingEvent);
            }
        }

        this.notifyInterfaceRenderer();
    }

    extractEventFromMap(event: SAIEvent) : SAIEvent {
        for(let eventPos in this.eventsMap) {
            let foundEvent = this.eventsMap[eventPos][event.getId()];
            if(foundEvent !== undefined) {
                delete this.eventsMap[eventPos][event.getId()];
                return foundEvent;
            }
        }
        return undefined;
    }

    addEventToMap(event:SAIEvent):void {
        if(this.eventsMap[event.getPosition()] === undefined){
            this.eventsMap[event.getPosition()] = {};
        }
        this.eventsMap[event.getPosition()][event.getId()] = event;
    }

    notifyInterfaceRenderer(){
        this.renderer.renderEvents();
    }

    public resetExistingEvents() {
        this.currentPeriods = [];
    }

    public getEvents(start: moment.Moment, end: moment.Moment, position:string) : Array<SAIEvent> {
        let eventsToRender: Array<SAIEvent> = [];
        for(let eventId in this.eventsMap[position]) {
            let curEv = this.eventsMap[position][eventId];
            if(curEv.getStart().isSameOrBefore(end) && curEv.getEnd().isSameOrAfter(start)) {
                eventsToRender.push(curEv);
            }
        }

        return eventsToRender;
    }

    getCardLabels(moment: moment.Moment, callback : GetCardLabelsFunction) {
        if(this.getCardLabelsFunction) {
            this.getCardLabelsFunction(moment, callback);
        } else {
            console.warn('No card labels function provided.');
        }
    }
}

export {SAIEvent, SAIEventManager, SAITimePeriod, EventFetchFunction, SAIEventPosition};