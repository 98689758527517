'use strict';

import * as _ from 'lodash';
import { ConfigurationElement } from './ConfigurationElement';
import { DomainContext } from '../DomainContext';

class PopupMenuItem {
    private element: { [prop: string] : any};
    private subItems: Array<PopupMenuItem>;

    constructor(popupMenuElement : any) {
        this.element = popupMenuElement;
        this.subItems = [];
        for(let key in popupMenuElement.subItems) {
            this.subItems.push(new PopupMenuItem(popupMenuElement.subItems[key]));
        }
    }

    public getItemId(): string {
        return this.element.itemId;
    }

    public getLabel(): string {
        return this.element.label;
    }

    public getIcon(): string {
        if(this.element.icon !== undefined) {
            return this.element.icon;
        } else {
            console.warn('Missing popupmenu icon for popup : ' + this.getLabel());
            return 'sai-missing';
        }
    }

    public getType(): string {
        return this.element.type;
    }

    public getScope(): string {
        return this.element.scope;
    }

    public getNotification(): any {
        return this.element.notification;
    }

    public getSubItems(): Array<any> {
        return this.subItems;
    }

    public getVisible(): string {
        return this.element.visible;
    }

    public getPopupId(): string {
        return this.element.popupId;
    }

    public getActionId(): string {
        return this.element.actionId;
    }

    public hasSubItems() : boolean {
        return this.subItems.length > 0;
    }
}

class PopupMenu extends ConfigurationElement{
    private popupMenuList : { [popupId: string] : { order: number, items: Array<PopupMenuItem> } };
    private initialConfig: any;
    public static SCOPE_ALWAYS:string = 'AllVisibleRecords';
    public static SCOPE_SINGLE:string = 'SingleRecord';
    public static SCOPE_MULTIPLE:string = 'SelectedRecords';

    constructor(config: any, context: DomainContext) {
        super(config, context);
        this.buildStructure(config);
        this.initialConfig = config;
    }

    /**
     * Initialisation of the map
     * popupmenu id -> [popup actions]
     */
    private buildStructure(config: any) : void {
        this.popupMenuList = {};
        let counter = 1;
        for (var actionIndex in config.actions) {
            var popup =  config.actions[actionIndex];
            var popupId = popup.popupId;
            if (this.popupMenuList[popupId] === undefined) {
                this.popupMenuList[popupId] = {
                    order: counter,
                    items: []
                };
                counter++;
            }
            this.popupMenuList[popupId].items.push(new PopupMenuItem(popup));
        }
    }

    /**
     * Returns a third popup menu that is the merge between the current context
     * one and the one given as parameter. The merge rules are the following ones :
     * - If two popupMenu share the same popupMenuId actions definition, then
     * the system keeps only the actions of the one given as argument
     * - If two popupMenu have different popupMenuId actions, then the new
     * popup menu will keep both actions list
     *
     * Exemple :
     *
     * PopupMenu 1
     *  - PopupMenuId A
     *  -- Action 1
     *  -- Action 2
     *  - PopupMenuId B
     *  -- Action 1
     *
     * PopupMenu 2
     *  - PopupMenuId A
     *  -- Action 3
     *  -- Action 4
     *  - PopupMenuId C
     *  -- Action 1
     *
     * Final PopupMenu
     *  - PopupMenuId A
     *  -- Action 3
     *  -- Action 4
     *  - PopupMenuId B
     *  -- Action 1
     *  - PopupMenuId C
     *  -- Action 1
     *
     * @param secondaryPopup The popup to use for the merge with the current one
     */
    public appendOverride(secondaryPopup : PopupMenu) {
        let newPopup = this.clone();
        let merging = secondaryPopup.clone();

        var secondaryPopupElements = secondaryPopup.popupMenuList;
        for (var secondaryPopId in secondaryPopupElements) {
            newPopup.setActions(secondaryPopId, secondaryPopupElements[secondaryPopId].items);
        }

        return newPopup;
    }

    /**
     * Returns all actions as a flatten list of PopupMenuItem
     */
    public getActions(scope: Array<string>): Array<PopupMenuItem> {
        let me = this;
        let actionKeys = Object.keys(this.popupMenuList);
        let sortedEntries = actionKeys.sort(function(a:string, b:string) {
            return me.popupMenuList[a].order <= me.popupMenuList[b].order ? -1 : 1;
        })
        return sortedEntries.reduce(function(previous, key) {
            let currentItems = _.filter(me.popupMenuList[key].items, function(item: PopupMenuItem) {
                if(item.hasSubItems()) {
                    let hasSome = false;
                    for(let subEntry in item.getSubItems()) {
                        if(!item.getSubItems()[subEntry].scope || scope.indexOf(item.getSubItems()[subEntry].scope) >= 0){
                            hasSome = true;
                            break;
                        }
                    }
                    return hasSome;
                } else {
                    return !item.getScope() || scope.indexOf(item.getScope()) >= 0;
                }
            })
            return previous.concat(currentItems);
        }, []);
    }

    public getVisibleActions(scope: Array<string>, values : {[colId:string]: {name: string, value: string}}, columnMap : {[dfId:string]: string}) : Array<PopupMenuItem> {
        let me = this;
        let actionKeys = Object.keys(this.popupMenuList);
        let sortedEntries = actionKeys.sort(function(a:string, b:string) {
            return me.popupMenuList[a].order <= me.popupMenuList[b].order ? -1 : 1;
        })
        return sortedEntries.reduce(function(previous, key) {
            let currentItems = _.filter(me.popupMenuList[key].items, function(item: PopupMenuItem) {
                if(item.hasSubItems()) {
                    let hasSome = false;
                    for(let subEntry in item.getSubItems()) {
                        if(!item.getSubItems()[subEntry].scope || scope.indexOf(item.getSubItems()[subEntry].scope) >= 0){
                            if (me.isVisible(item.getSubItems()[subEntry],values, columnMap)) {
                                hasSome = true;
                                break;
                            }
                        }
                    }
                    return hasSome;
                } else {
                    return (!item.getScope() || scope.indexOf(item.getScope()) >= 0) && me.isVisible(item, values, columnMap);
                }
            })
            return previous.concat(currentItems);
        }, []);
    }

    public isVisible(popupItem : PopupMenuItem, values : {[colId:string]: {name: string, value: string}}, columnMap : {[dfId:string]: string}) : boolean {
        let visibleCondition = popupItem.getVisible();
        if(visibleCondition === undefined || visibleCondition === 'true') {
            return true;
        } else if(visibleCondition === 'false') {
            return false;
        }

        //Let's see if the popup menu pattern follows something like
        // SALEMPDOSSIER.CUSTOM20 : IN=false,OTHER=true,OUT=true
        let pattern = /(.+)\s:\s(.+=(?:false|true))(,.+=(?:false|true))*/g
        if(visibleCondition.match(pattern)) {
            let matcher = pattern.exec(visibleCondition);
            let datafield = matcher[1];
            let allCases = matcher[2].split(',');
            let valuesCases = {};
            for(let key in allCases) {
                let eqSplit = allCases[key].split('=').map(item => item.trim());
                valuesCases[eqSplit[0]] = eqSplit[1] === 'true';
            }
            let columnId = (columnMap || {})[datafield];
            if(columnId) {
                let currentCol = (values || {})[columnId];
                if(currentCol && valuesCases[currentCol.value] !== undefined) {
                    return valuesCases[currentCol.value];
                } else {
                    return false;
                }
            }
        }
        throw new Error('The given visibility pattern is not implemented, contact your administrator');
    }

    public hasActions(scope: Array<string>) : boolean {
        return this.getActions(scope).length > 0;
    }

    /**
     * Sets the actions as the new list of actions of the given popupMenuId
     * @param popupId The common popupId of the actions
     * @param items The new overriding actions
     */
    public setActions(popupId: string, items: Array<PopupMenuItem>) {
        if(!this.popupMenuList[popupId]) {
            this.popupMenuList[popupId] = {
                order: Object.keys(this.popupMenuList).length + 1,
                items: items
            };
        } else {
            this.popupMenuList[popupId].items = items;
        }
    }

    /**
     * Returns a new PopupMenu with the exact same initial config. It's important
     * to notice that any later action on the popupmenu won't be taken into consideration
     * when clonning the popup. It's thus important to clone the popups before
     * making operations on them.
     */
    public clone(): PopupMenu {
        return new PopupMenu(this.initialConfig, this.context);
    }
}

export { PopupMenu, PopupMenuItem };